<template>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1> {{ $t("dashboard.addTransaction.titleBulkRegistration") }}</h1>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.bulkArchive") }}<span> Folder Name: &lt;DATE&gt; &lt;Procedure Number&gt; &#38; &lt;Seller(s)&gt; &#38; &lt;Buyer(s)&gt; &#38; &lt;Area Name&gt; &#38; &lt;Building Name&gt;</span></h4>
					<div class="row mb-3">
						<div class="col-12">
							<form @submit.prevent="validateForm" novalidate>
								<div class="form-group row">
									<div class="col-sm-8 col-lg-9 col-xl-10">
										<div class="custom-file">
											<input type="file" class="form-control" :class="{ of : files.length == 1, mf : files.length > 1 && files.length < 10, mf10 : files.length >= 10 }" ref="files" v-on:change="filesValidation" accept="image/*, application/*" webkitdirectory directory multiple>
										</div>
									</div>
									<div class="col-sm-4 col-lg-3 col-xl-2 mt-2 mt-sm-0">
										<button type="submit" @click.prevent="upload()" class="btn btn-main btn-upload" v-bind:disabled="loading">{{ $t("dashboard.transaction.upload") }}</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table align-middle">
									<thead>
										<tr>
											<th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in columns">{{ column }}</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(key, index) of Object.keys(transactions)">
											<td>{{ index + 1 }}</td>
											<td><router-link :to="'/!/' + transactions[key].id">{{ transactions[key].id }}</router-link></td>
											<td>{{ transactions[key].folderName }}</td>
											<td v-if="transactions[key].status == 'ERROR'"><img src="../../../assets/Error.gif" :alt="transactions[key].status" width="45"/></td>
											<td v-if="transactions[key].status == 'UPLOADING'"><img src="../../../assets/Fidget-spinner.gif" :alt="transactions[key].status" width="45"/></td>
											<td v-if="transactions[key].status == 'DONE'"><img src="../../../assets/check-mark-verified.jpg" :alt="transactions[key].status" width="45"/></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "AddTransactionFromFolder",
	data() {
		return {
			loading: false,
			files: [],
			fileList: [],
			transactions: {},
			selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
			columns: [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transactions.link'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.status')
			]
		}
	},
	methods: {
		...mapActions([
			"addTransaction",
			"uploadFiles"
		]),
		async upload() {
			this.$toast.clear();
			let fileArr = this.$refs.files.files
			if(fileArr.length == 0) {
				this.$toast.error('Folder is empty.');
				return;
			}
			this.transactions = {}
			// 03-08-2021 886-2021 ALKHALDY & RAMAZAN & MARSA DUBAI ANBAR-V1
			for(let file of fileArr) {
				let folderName = file.webkitRelativePath.split("/")
				folderName = folderName[folderName.length - 2]
				let fileName = file.webkitRelativePath.split("/")
				fileName = fileName[fileName.length - 1]
				let temp = folderName.split(" ")
				let date = temp[0]
				let procedureNumber = temp[1]
				if(this.transactions[folderName] == null) {
					this.transactions[folderName] = {};
					this.transactions[folderName]["files"] = [];
				}
				temp = folderName.split("&")

				this.transactions[folderName]["sellerName"] = temp[1] || ""
				this.transactions[folderName]["buyerName"] = temp[2] || ""
				this.transactions[folderName]["area"] = temp[3] || ""
				this.transactions[folderName]["building"] = temp[4] || ""

				this.transactions[folderName]["folderName"] = folderName
				this.transactions[folderName]["date"] = date
				this.transactions[folderName]["id"] = ""
				this.transactions[folderName]["status"] = "UPLOADING"
				this.transactions[folderName]["procedureNumber"] = procedureNumber
				this.transactions[folderName]["files"].push(file)
				// if(file.webkitRelativePath.split("/").length != 2) {
				// 	this.$toast.error('Folder has folders in it.');
				// 	return;
				// }
				// fileName = file.webkitRelativePath.split("/")[1]
			}



			for(let key of Object.keys(this.transactions)) {


				let date = this.transactions[key].date
				date = date.split("-")
				date = new Date(date[2] + "-" + date[1] + "-" + date[0]) 
				let payload = {};

				payload.status = "PAID-INOFFICE";
				payload.type = "RT";
				payload.procedureNumber = this.transactions[key].procedureNumber
				payload.transactionDate = date.toISOString();
				payload.totalAmount = (1 * 100).toString();
				payload.commissionAmount = (0 * 100).toString();
				payload.notes = this.transactions[key].folderName;

				payload.sellerName = this.transactions[key].sellerName
				payload.buyerName = this.transactions[key].buyerName
				payload.area = this.transactions[key].area
				payload.building = this.transactions[key].building


				this.loading = true;
				await this.addTransaction(payload)
				.then(async data => {
					//this.$toast.show('Transaction "' + transactions[key].folderName + '" successfully added');
					
					// this.$router.push(`/${this.$i18n.locale}/!/${data.id}`);

					this.transactions[key].id = data.id

					let newPayload = {};
					newPayload.id = data.id;
					newPayload.files = this.transactions[key].files;      
					this.loading = true;
					await this.uploadFiles(newPayload)
					.then(() => {
						this.transactions[key].status = "DONE"
					})
					.catch(err => {
						//this.$toast.clear();
						this.$toast.error(err.msg + " (" + payload.procedureNumber + ")", { duration: 1000 * 30});
						this.transactions[key].status = "ERROR"
					})
				})
				.catch(err => {
					//this.$toast.clear();
					this.$toast.error(err.msg + " (" + payload.procedureNumber + ")", { duration: 1000 * 30});
					this.transactions[key].status = "ERROR"
				})
				.finally(() => {
					this.loading = false;
				});



			}
			return;
        }
	},
	watch: {
        '$i18n.locale'() {
            if(this.$i18n.locale == 'en') {
                this.columns = [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transactions.link'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.status')
				];
            } else {
                this.columns = [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transactions.link'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.status')
				];
                //this.columns.reverse();
            }
        }
    }
}
</script>

<style scoped>
.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}





h1 span {
	font-size: 1rem;
	margin: 0 5px;
}

.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.container[dir="rtl"] h4 span {
	font-family: tajawal-regular;
}

.container h4 span {
	font-family: lato-regular;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header .buttons {
	display: flex;
}

.header .buttons > button {
	margin-right: 5px;
}

.header .buttons > button:last-of-type {
	margin-right: 0;
}

*[dir="rtl"] .header .buttons > button:last-of-type {
	margin-right: 5px;
}

*[dir="rtl"] .header .buttons > button:first-of-type {
	margin-right: 0;
}

table.table.ar {
    text-align: end;
}

.btn-upload {
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin: 0;
	width: 100%;
}

*[dir="rtl"] .form-control[type="file"]::before {
  content: "اختر المرفقات";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eaecef;
  width: 90px;
  transition: .1675s background-color;
}

*[dir="rtl"] .form-control[type="file"]:hover::before {
	background-color: #dee0e3;
}

*[dir="rtl"] .form-control[type="file"]::after {
	content: "لم يتم اختيار أي مرفقات";
	position: absolute;
	z-index: 3;
	display: block;
	width: 90px;
	top: 50px;
	right: 126px;
	background-color: #ffffff;
}

*[dir="rtl"] .form-control[type="file"].of::after {
	display: none;
}

*[dir="rtl"] .form-control[type="file"].mf::after {
	content: 'مرفقات';
	right: 138px;
}

*[dir="rtl"] .form-control[type="file"].mf10::after {
	content: '10+ مرفقات';
	right: 126px;
}

@media only screen and (max-width: 1400px) {
	.header {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 767px) {
	.header .buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
	.header .buttons button {
		margin: 0;
	}
}
</style>