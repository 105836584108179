import { createStore } from 'vuex';
import auth from './auth';
import dashboard from './dashboard';
import payment from './payment';

export default createStore({
	state: {
		tawkInitialized: false,
	},
	mutations: {
		INITIALIZE_TAWK(state) {
			let Tawk_API=Tawk_API||{};
			let s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
			s1.async=true;
			s1.src='https://embed.tawk.to/612dce6b649e0a0a5cd3c7b9/1fedesljl';
			s1.charset='UTF-8';
			s1.setAttribute('crossorigin','*');
			s0.parentNode.insertBefore(s1,s0);
			state.tawkInitialized = true;
		}
	},
	actions: {
		initializeTawk({ commit }) {
			commit('INITIALIZE_TAWK');
		}
	},
	getters: {
		tawkInitialized(state) {
			return state.tawkInitialized;
		}
	},
	modules: {
		auth,
		dashboard,
		payment
	}
})
