<template>
    <div class="footer-container" id="footer" :dir="[ $i18n.locale == 'ar' ? 'rtl' : '' ]">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="nv-sm">
                        <div class="nav-links">
                            <h3>{{ $t('index.footer.siteMap') }}</h3>
                            <router-link :to="`/${$i18n.locale}`">{{ $t('index.nav.home') }}</router-link>
                            <a href="https://dorchesterpropertiesregistrationtrusteellc.tawk.help/" target="_blank">{{ $t('index.nav.services') }}</a>
                            <router-link :to="`/${$i18n.locale}/book`">{{ $t('index.nav.book') }}</router-link>
                        </div>
                        <div class="social-media">
                            <a href="#" title="WhatsApp">
                                <svg fill="#f7f6ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="32px" height="32px"><path d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z"/></svg>
                            </a>
                            <a href="#" title="Instagram">
                                <svg fill="#f7f6ff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="32px" height="32px"><path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"/></svg>
                            </a>
                            <a href="#" title="Twitter">
                                <svg fill="#f7f6ff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="32px" height="32px"><path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/></svg>
                            </a>
                            <a href="#" title="FaceBook">
                                <svg fill="#f7f6ff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="32px" height="32px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"/></svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="partners">
                        <img src="../../../assets/ameen2.jpeg" alt="Ameen" class="partner">
                        <img src="../../../assets/ISO_News.jpeg" alt="ISO" class="partner">
                    </div>
                    <div class="powered-by">
                        <p>{{ $t('index.footer.poweredBy') }}</p>
                        <img src="../../../assets/foloosi.png" alt="Foloosi" class="foloosi">
                    </div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 order-2 order-sm-1">
                    <p class="copyright">{{ $t('index.footer.copyright') }} &copy; {{ new Date().getFullYear() }} {{ $t('index.footer.allRightsReserved') }}</p>
                </div>
                <div class="col-12 col-sm-6 order-1 order-sm-2">
                    <p class="hours">{{ $t('index.footer.workingHours') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-color: rgb(36, 33, 31);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.separator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid rgb(34, 30, 29);
}

.nv-sm {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nv-sm h3 {
    color: #e1e1e1;
}

.nav-links {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.nav-links a {
    width: fit-content;
    color: #88aec7;
    text-decoration: none;
    transition: all .3s ease;
    margin: 2.5px 0;
}

.nav-links a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.social-media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0px;
}

.social-media > a {
    margin: 0 5px;
}

.social-media > a:first-of-type {
    margin-left: 0;
}

.social-media > a:last-of-type {
    margin-right: 0;
}

*[dir="rtl"] .social-media > a:last-of-type {
    margin-right: 5px;
    margin-left: 0;
}

*[dir="rtl"] .social-media > a:first-of-type {
    margin-left: 5px;
    margin-right: 0;
}

svg:hover {
    fill: #f0e5ce;
}

p.copyright, p.hours {
    margin: 0;
    font-size: 13px;
    padding: 15px 0px;
}

p.hours {
    text-align: end;
}

p.copyright {
    text-align: start;
}

.partners {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0px;
}

.partners > img:first-of-type {
    margin-bottom: 10px;
}

.powered-by {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px 0px;
}

.powered-by p {
    margin: 0;
    font-family: lato-bold;
    margin-right: 15px;
}

*[dir="rtl"] .powered-by p {
    font-family: tajawal-bold;
    margin-left: 15px;
    margin-right: 0;
}

img.partner {
    width: 175px;
}

img.foloosi {
    width: 100px;
}

@media only screen and (max-width: 575px) {
    .partners {
        align-items: center;
    }
    .social-media {
        justify-content: center;
    }
    p.hours {
        text-align: center;
    }
    p.copyright {
        text-align: center;
    }
    .powered-by {
        justify-content: center;
    }
}
</style>