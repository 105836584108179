<template>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1> {{ $t("dashboard.addUser.title") }}</h1>
					<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{ $t("dashboard.addUser.add") }}</button>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.addUser.userInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
                            <div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.role") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.role" v-bind:class="{ invalid : errors.role }" id="role">
                                        <option disabled selected value="null">{{ $t('dashboard.addUser.chooseRole') }}</option>
                                        <option :value="$t('dashboard.addUser.roles.admin.id')">{{ $t('dashboard.addUser.roles.admin.value') }}</option>
                                        <option :value="$t('dashboard.addUser.roles.user.id')">{{ $t('dashboard.addUser.roles.user.value') }}</option>
										<option :value="$t('dashboard.addUser.roles.reportUser.id')">{{ $t('dashboard.addUser.roles.reportUser.value') }}</option>
										<option :value="$t('dashboard.addUser.roles.superUser.id')">{{ $t('dashboard.addUser.roles.superUser.value') }}</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.role">
										{{ errors.role }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.name }"
										type="text"
										class="form-control"
										id="name"
										v-model="inputs.name"
										autocomplete="off"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.name">
										{{ errors.name }}
									</div>
								</div>	
							</div>
                        </div>
                        <div class="col-lg-6">
							<div class="row mb-3">
								<label for="email" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.email") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.email }"
										type="email"
										class="form-control"
										id="email"
										v-model="inputs.email"
										autocomplete="off"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.email">
										{{ errors.email }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="mobileNumber" class="col-form-label col-sm-3">{{
									$t("dashboard.addUser.mobileNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.mobileNumber }" type="text" class="form-control"
										id="mobileNumber" v-model="inputs.mobileNumber" autocomplete="off" maxlength="10" />
									<div class="invalid-feedback" v-if="errors.mobileNumber">
										{{ errors.mobileNumber }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="password" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.password") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.password }"
										type="password"
										class="form-control"
										id="password"
										v-model="inputs.password"
										autocomplete="new-password"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.password">
										{{ errors.password }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validator from 'validator';
import { mapActions } from "vuex";

export default {
	name: "AddUser",
	data() {
		return {
			loading: false,
			formValidated: false,
			inputs: {
                role: null,
				name: null,
				email: null,
                password: null,
				mobileNumber: null,
			},
			errors: {
                role: null,
				name: null,
				email: null,
                password: null,
				mobileNumber: null,
			},
		}
	},
	methods: {
		...mapActions([
			"addAccount"
		]),
		async validateForm() {
			if(this.loading) return;
			this.formValidated = true;

            const nameValid = this.nameValid();
            const emailValid = this.emailValid();
            const roleValid = this.roleValid();
            const passwordValid = this.passwordValid();
			const mobileNumberValid = this.mobileNumberValid();
			
			if(nameValid && emailValid && roleValid && passwordValid && mobileNumberValid) {

					let payload = {};

					payload.role = this.inputs.role;
					payload.name = this.inputs.name;
					payload.email = this.inputs.email;
					payload.password = this.inputs.password;
					payload.mobileNumber = this.inputs.mobileNumber;

					this.loading = true;
					await this.addAccount(payload)
					.then(data => {
						this.$toast.show('User successfully added');
                        this.$router.push(`/${this.$i18n.locale}/!/user/${data.id}`);
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.show(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
        roleValid() {
            if(!this.inputs.role || validator.isEmpty(this.inputs.role)) {
				this.errors.role = this.$i18n.locale == 'en' ? 'Role cannot be empty' : 'خانة نوع الحساب فارغة';
				return false;
			}
			this.errors.role = null;
			return true;
        },
		nameValid() {
			if(!this.inputs.name || validator.isEmpty(this.inputs.name)) {
				this.errors.name = this.$i18n.locale == 'en' ? 'Name cannot be empty' : 'خانة الإسم فارغة';
				return false;
			}
			this.errors.name = null;
			return true;
		},
		emailValid() {
			if(!this.inputs.email || !validator.isEmail(this.inputs.email)) {
				this.errors.email = this.$i18n.locale == 'en' ? 'Email is incorrect' : 'الإيميل غير صحيح';
				return false;
			}
			this.errors.email = null;
			return true;
		},
		mobileNumberValid() {
			if (!this.inputs.mobileNumber || !validator.isMobilePhone(this.inputs.mobileNumber) || this.inputs.mobileNumber.match(/^[0]{1}[5]{1}[0-9]{1}[0-9]{7}$/g) == null) {
				this.errors.mobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			this.errors.mobileNumber = null;
			return true;
		},
        passwordValid() {
			if(!this.inputs.password || validator.isEmpty(this.inputs.password)) {
				this.errors.password = this.$i18n.locale == 'en' ? 'Password cannot be empty' : 'خانة كلمة السر فارغة';
				return false;
			}
			this.errors.password = null;
			return true;
		},
	},
	watch: {
		'$i18n.locale'() {
            if(this.formValidated) {
                this.nameValid();
                this.emailValid();
            }
		},
		'inputs.fulfillerName'() {
			if(this.formValidated) this.nameValid();
		},
        'inputs.fulfillerEmail'() {
			if(this.formValidated) this.emailValid();
		},
	}
}
</script>

<style scoped>
.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
</style>