<template>
    <div class="container services" id="services">
        <div class="row justify-content-center">
            <div class="col-12 text-center main">
                <h1>{{ $t('index.services.title') }}</h1>
                <p>{{ $t('index.services.subtitle') }} <a target="_blank" href="https://dorchesterpropertiesregistrationtrusteellc.tawk.help/">{{ $t('index.services.clickHere') }}</a></p>
            </div>
        </div>

        <div class="row justify-content-center" :dir="[ $i18n.locale == 'en' ? 'ltr' : 'rtl' ]">
            <div class="service" v-for="(service, index) in services" v-bind:key="index">
                <div class="card">
                    <img :src="service.img" :alt="service.title">
                    <div class="content">
                        <h2>{{ service.title }}</h2>
                        <p v-if="service.type">{{ service.type }}</p>
                        <p v-else><br></p>
                        <p>{{ service.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services',
    data() {
        return {
            services: [
                {
                    img: require('../../../assets/services/transfer.jpg'),
                    title: this.$t('index.services.transfer.title'),
                    type: this.$t('index.services.transfer.type'),
                    description: this.$t('index.services.transfer.description')
                },
                {
                    img: require('../../../assets/services/grant.jpg'),
                    title: this.$t('index.services.grant.title'),
                    type: this.$t('index.services.grant.type'),
                    description: this.$t('index.services.grant.description')
                },
                {
                    img: require('../../../assets/services/mortgage.jpg'),
                    title: this.$t('index.services.mortgage.title'),
                    description: this.$t('index.services.mortgage.description')
                },
                {
                    img: require('../../../assets/services/development.jpg'),
                    title: this.$t('index.services.development.title'),
                    type: this.$t('index.services.development.type'),
                    description: this.$t('index.services.development.description')
                },
                {
                    img: require('../../../assets/services/preRegistration.jpg'),
                    title: this.$t('index.services.preRegistration.title'),
                    type: this.$t('index.services.preRegistration.type'),
                    description: this.$t('index.services.preRegistration.description')
                },
                {
                    img: require('../../../assets/services/inHouseServices.jpg'),
                    title: this.$t('index.services.inHouseServices.title'),
                    description: this.$t('index.services.inHouseServices.description')
                }
            ]
        }
    },
    watch: {
        '$i18n.locale'() {
            this.services = [
                {
                    img: require('../../../assets/services/transfer.jpg'),
                    title: this.$t('index.services.transfer.title'),
                    type: this.$t('index.services.transfer.type'),
                    description: this.$t('index.services.transfer.description')
                },
                {
                    img: require('../../../assets/services/grant.jpg'),
                    title: this.$t('index.services.grant.title'),
                    type: this.$t('index.services.grant.type'),
                    description: this.$t('index.services.grant.description')
                },
                {
                    img: require('../../../assets/services/mortgage.jpg'),
                    title: this.$t('index.services.mortgage.title'),
                    description: this.$t('index.services.mortgage.description')
                },
                {
                    img: require('../../../assets/services/development.jpg'),
                    title: this.$t('index.services.development.title'),
                    type: this.$t('index.services.development.type'),
                    description: this.$t('index.services.development.description')
                },
                {
                    img: require('../../../assets/services/preRegistration.jpg'),
                    title: this.$t('index.services.preRegistration.title'),
                    type: this.$t('index.services.preRegistration.type'),
                    description: this.$t('index.services.preRegistration.description')
                },
                {
                    img: require('../../../assets/services/inHouseServices.jpg'),
                    title: this.$t('index.services.inHouseServices.title'),
                    description: this.$t('index.services.inHouseServices.description')
                }
            ]
        }
    }
}
</script>

<style scoped>
.container.services {
    margin-bottom: 30px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container.services .main {
    margin-bottom: 35px;
}

.container.services .service {
    margin-bottom: 15px;
}

.card {
    border: 2.5px solid #f7f7f7;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 4px;
    height: 100%;
}

.card, .card img {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.card .content {
    padding: 10px;
}

.row {
    width: 100%;
}

.row > .service {
    margin: 0 1%;
}

.service {
    flex: 0 0 1;
    width: 28%;
}

@media only screen and (max-width: 991px) {
    .service {
        flex: 0 0 1;
        width: 42%;
    }

    .row {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .service {
        flex: 0 0 1;
        width: 95%;
    }
}
</style>