<template>
    <nav class="navbar navbar-expand-xl navbar-light mb-2"
        :class="[$i18n.locale == 'en' ? 'en text-start' : 'ar text-end']">
        <div class="container">
            <router-link class="navbar-brand" :to="`/${$i18n.locale}/!`">
                <img src="../../../assets/DorchesterLogo_AK.png" alt="Dorchester" height="30">
            </router-link>
            <p style="font-style: italic;">{{ name }}</p>
            <button class="navbar-toggler" id="toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav ms-auto">
                    <router-link v-if="role == 'user' || role == 'superUser' || role == 'admin'"
                        :to="`/${$i18n.locale}/!`" class="nav-link"
                        :class="{ active: $route.path == `/${$i18n.locale}/!` || $route.path == `/${$i18n.locale}/!/` }"
                        @click.prevent="closeNavbar">{{ $t('dashboard.nav.transactions') }}</router-link>
                    <router-link v-if="role == 'superUser' || role == 'admin'" :to="`/${$i18n.locale}/!/all`"
                        class="nav-link" :class="{ active: $route.path.includes('all') }"
                        @click.prevent="closeNavbar">{{ $t('dashboard.nav.allTransactions') }}</router-link>
                    <router-link v-if="role == 'admin'" :to="`/${$i18n.locale}/!/analytics`" class="nav-link"
                        :class="{ active: $route.path.includes('analytics') }" @click.prevent="closeNavbar">{{
                            $t('dashboard.nav.analytics') }}</router-link>
                    <router-link v-if="role == 'reportUser' || role == 'admin'" :to="`/${$i18n.locale}/!/reports`"
                        class="nav-link" :class="{ active: $route.path.includes('reports') }"
                        @click.prevent="closeNavbar">{{ $t('dashboard.nav.reports') }}</router-link>
                    <router-link v-if="role == 'admin'" :to="`/${$i18n.locale}/!/users`" class="nav-link"
                        :class="{ active: $route.path.includes('users') }" @click.prevent="closeNavbar">{{
                            $t('dashboard.nav.users') }}</router-link>
                    <router-link v-if="role == 'user' || role == 'superUser' || role == 'admin' || role == 'reportUser'"
                        :to="`/${$i18n.locale}/!/user/${id}`" class="nav-link"
                        :class="{ active: $route.path.includes(id) }" @click.prevent="closeNavbar">{{
                            $t('dashboard.nav.settings') }}</router-link>
                    <router-link v-if="!refreshCookie" :to="`/${$i18n.locale}/!/login`" class="nav-link"
                        :class="{ active: $route.path.includes('login') }" @click.prevent="closeNavbar">{{
                            $t('dashboard.nav.login') }}</router-link>
                    <a v-else href="#" @click.prevent="logoutAccount" class="nav-link">{{ $t('dashboard.nav.logout')
                        }}</a>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../../assets/icons/united-kingdom.png" alt="English" height="20"
                                v-if="$i18n.locale == 'en'">
                            <img src="../../../assets/icons/united-arab-emirates.png" alt="Arabic" height="20" v-else>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                            <li class="en">
                                <a class="dropdown-item" href="#" @click.prevent="setLocale('en')">
                                    <img src="../../../assets/icons/united-kingdom.png" alt="English"
                                        height="20">English
                                </a>
                            </li>
                            <li class="ar">
                                <a class="dropdown-item" href="#" @click.prevent="setLocale('ar')">
                                    <img src="../../../assets/icons/united-arab-emirates.png" alt="Arabic"
                                        height="20">العربية
                                </a>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Navbar',
    data() {
        return {
            expanded: false,
        }
    },
    methods: {
        ...mapActions([
            'logout'
        ]),
        closeNavbar() {
            if (window.innerWidth <= 991) document.getElementById('toggler').click();
        },
        setLocale(locale) {
            this.$i18n.locale = locale;
            this.$router.push({
                params: { lang: locale }
            });
        },
        async logoutAccount() {
            await this.logout()
                .then(() => {
                    this.$router.push(`/${this.$i18n.locale}/!/login`)
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg);
                });
        },
        toggleNavbar() {
            this.expanded = !this.expanded;
        },
    },
    computed: {
        ...mapGetters([
            'refreshCookie',
            'role',
            'id',
            'name'
        ])
    }
}
</script>

<style scoped>
@import "../../../../public/vcalendercss/style";

.navbar {
    background-color: transparent !important;
    padding: 15px;
    border-bottom: 2.5px solid #f6f6f6;
}

.navbar-brand,
.navbar-toggler {
    z-index: 11;
}

.navbar-nav>a {
    margin: 0 10px !important;
}

li.dropdown {
    margin: 0 0 0 10px !important;
}

li img {
    margin-right: 10px;
}

.ar {
    font-family: tajawal-bold;
}

.en {
    font-family: lato-bold;
}

.dropdown-menu {
    background-color: rgba(82, 77, 73, 0.541);
    backdrop-filter: blur(3.5px);
    border-radius: 3px;
}

li a {
    color: #ffffff;
}
</style>