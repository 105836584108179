<template>
    <Navbar></Navbar>
    <router-view></router-view>
</template>

<script>
import Navbar from '../components/dashboard/shared/Navbar';

export default {
    name: 'DashboardIndex',
    components: {
        Navbar,
    }
}
</script>