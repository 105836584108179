<template>
    <div class="container" :dir="[ $i18n.locale == 'ar' ? 'rtl' : 'ltr' ]">
        <div class="row justify-content-center d-flex align-items-center">
            <div class="col-lg-7 col-md-10 col-sm-12">
                <div class="card" v-if="forgotPassword == false">
                    <h2 class="title">{{ $t('dashboard.login.title') }}</h2>
                    <form @submit.prevent="validateForm" novalidate>
                        <div class="form-group">
                            <label for="inputEmail">{{ $t('dashboard.login.email') }}</label>
                            <input v-model="input.email" type="email" class="form-control" id="inputEmail" :placeholder="$t('dashboard.login.emailPlaceholder')" v-bind:class="[ errors.email != '' ? 'invalid' : '' ]" required>
                            <div class="invalid-feedback">
                                {{ errors.email }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword">{{ $t('dashboard.login.password') }}</label>
                            <input v-model="input.password" :type="showPassword ? 'text' : 'password'" class="form-control" id="inputPassword" :placeholder="$t('dashboard.login.passwordPlaceholder')" v-bind:class="[ errors.password != '' ? 'invalid' : '' ]" required>
                            <div class="invalid-feedback">
                                {{ errors.password }}
                            </div>
                        </div>
                        <div class="form-group" v-if="otpRequired == true">
                            <label for="inputOTP">{{ $t('dashboard.login.otp') }}</label>
                            <input v-model="input.otp" class="form-control" type="text" id="inputOTP" autocomplete="off" :placeholder="$t('dashboard.login.otpPlaceholder')" v-bind:class="[ errors.otp != '' ? 'invalid' : '' ]" required>
                            <div class="invalid-feedback">
                                {{ errors.otp }}
                            </div>
                        </div>
                        <button type="submit" class="btn btn-main" :disabled="loading">{{ $t('dashboard.login.login') }}</button>
                        <button v-if="showResendOtp == true" type="button" class="btn" @click.prevent="resendOtp()" :disabled="loading">{{ $t('dashboard.login.resendOtp') }}</button>
                        <button v-if="showResetPass == true && showResendOtp == false" type="button" class="btn btn-main" @click.prevent="openResetPassword()" :disabled="loading">{{ $t('dashboard.login.resetPassOpen') }}</button>
                    </form>
                </div>

                <div class="card" v-else>
                    <h2 class="title">{{ $t('dashboard.login.resetPassword') }}</h2>
                    <form @submit.prevent="resetPassword" novalidate>
                        <div class="form-group">
                            <label for="inputEmail">{{ $t('dashboard.login.email') }}</label>
                            <input v-model="input.email" type="email" class="form-control" id="inputEmail" :placeholder="$t('dashboard.login.emailPlaceholder')" v-bind:class="[ errors.email != '' ? 'invalid' : '' ]" required>
                            <div class="invalid-feedback">
                                {{ errors.email }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputMobile">{{ $t('dashboard.login.mobile') }}</label>
                            <input v-model="input.mobileNumber" type="text" class="form-control" id="inputMobile" :placeholder="$t('dashboard.login.mobilePlaceholder')" v-bind:class="[ errors.mobileNumber != '' ? 'invalid' : '' ]" required>
                            <div class="invalid-feedback">
                                {{ errors.mobile }}
                            </div>
                        </div>
                        <button type="submit" class="btn btn-main" :disabled="loading">{{ $t('dashboard.login.reset') }}</button>
                        <button v-if="showResetPass == true" type="button" class="btn btn-main" @click.prevent="closeResetPassword()" :disabled="loading">{{ $t('dashboard.login.resetPassClose') }}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import validator from 'validator';
import cookie from 'vue-cookies';

export default {
    name: 'Login',
    data() {
        return {
            input: {
                email: '',
                password: '',
                otp: '',
                mobileNumber: '',
            },
            errors: {
                email: '',
                password: '',
                otp: '',
                mobileNumber: '',
            },
            formValidated: false,
            loading: false,
            showPassword: false,
            showOtp: false,
            otpRequired: false,
            showResendOtp: false,
            showResetPass: true,
            forgotPassword: false,
        }
    },
    methods: {
        ...mapActions([
            'login',
            'passwordReset',
        ]),
        async validateForm() {
            if(this.loading) return;
            this.formValidated = true;

            const email = this.input.email;
            const password = this.input.password;
            const otp = this.input.otp;

            const emailValid = this.emailValidation();
            const passwordValid = this.passwordValidation();
            let otpValid = this.otpValidation();

            if(emailValid && passwordValid && otpValid) {
                this.loading = true;
                let body = { email, password }
                if(this.otpRequired == true) body = { email, password, otp }
                await this.login(body)
                .then((res) => {
                    if(res.status == 202) {
                        this.otpRequired = true; this.showResendOtp = true;
                        this.$toast.show(this.$i18n.locale == 'en'? 'OTP code sent' : 'تم إرسال الرمز')
                    } else {
                        console.log(res.data.account.requiresLogin)
                        if(res.data.account.requiresLogin == true) this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!/user/${res.data.account.id}?reset=true`);
                        else {
                            if(cookie.get("role") == "reportUser") {
                                this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!/reports`);
                            } else {
                                this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!`);
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        },
        async resetPassword() {
            if(this.loading) return;
            this.formValidated = true;

            const email = this.input.email;
            const mobileNumber = this.input.mobileNumber;

            const emailValid = this.emailValidation();
            const mobileNumberValid = this.mobileNumberValidation();

            if(emailValid && mobileNumberValid) {
                this.loading = true;
                let body = { email, mobileNumber }
                await this.passwordReset(body)
                .then((res) => {
                    if(res.status == 200) {
                        this.input.mobileNumber = '';
                        this.closeResetPassword();
                        this.$toast.show(this.$i18n.locale == 'en'? 'Password reset sent' : 'تم إرسال إعادة تعيين كلمة المرور')
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        },
        async resendOtp() {
            if(this.loading) return;
            this.formValidated = true;

            const email = this.input.email;
            const password = this.input.password;

            const emailValid = this.emailValidation();
            const passwordValid = this.passwordValidation();

            if(emailValid && passwordValid) {
                this.loading = true;
                let body = { email, password }
                await this.login(body)
                .then((res) => {
                    if(res.status == 202) {
                        this.otpRequired = true; this.showResendOtp = true;
                        this.$toast.show(this.$i18n.locale == 'en'? 'OTP code sent' : 'تم إرسال الرمز')
                    } else {
                        if(cookie.get("role") == "reportUser") {
                        this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!/reports`);
                        } else {
                            this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!`);
                        }
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        },
        openResetPassword() {
            this.forgotPassword = true;
        },
        closeResetPassword() {
            this.forgotPassword = false;
        },
        emailValidation() {
            if(validator.isEmpty(this.input.email)) {
                this.errors.email = this.$i18n.locale == 'en'? 'Email cannot be empty' : 'خانة الأيميل فارغة';
                return false;
            } else if(!validator.isEmail(this.input.email)) {
                this.errors.email = this.$i18n.locale == 'en' ? 'Email is not valid' : 'الإيميل غير صحيح';
                return false;
            } else {
                this.errors.email = '';
                return true;
            }
        },
        passwordValidation() {
            if(this.input.password === '') {
                this.errors.password = this.$i18n.locale == 'en' ? 'Password cannot be empty' : 'خانة كلمة السر فارغة';
                return false;
            } else {
                this.errors.password = '';
                return true;
            }
        },
        otpValidation() {
            if(this.otpRequired == false) {
                this.errors.otp = '';
                return true;
            }
            if(this.input.otp === '') {
                this.errors.otp = this.$i18n.locale == 'en' ? 'OTP cannot be empty' : 'خانة رمز المرة الواحدة فارغة';
                return false;
            } else {
                this.errors.otp = '';
                return true;
            }
        },
        mobileNumberValidation() {
			if (this.input.mobileNumber == null || !this.input.mobileNumber || !validator.isMobilePhone(this.input.mobileNumber) || this.input.mobileNumber.match(/^[0]{1}[5]{1}[0-9]{1}[0-9]{7}$/g) == null) {
				this.errors.mobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			this.errors.mobileNumber = '';
			return true;
		},
    },
    watch: {
        'input.email'() {
            if(this.formValidated) this.emailValidation();
        },
        'input.password'() {
            if(this.formValidated) this.passwordValidation();
        },
        '$i18n.locale'() {
            if(this.formValidated) { 
                this.passwordValidation();
                this.emailValidation();
            }
        }
    }
}
</script>

<style scoped>
.container {
    margin-top: 10vh;
}

h2.title {
    text-align: center;
    color: #363636;
}

.card {
    padding: 50px;
    background-color: #fcfcfc;
    border: 2.5px solid #f6f6f6;
}

@media only screen and (max-width: 767px) {
    .btn-main {
        width: 100%;
    }

    .card { 
        padding: 30px;
    }
}
</style>