import { createRouter, createWebHistory } from 'vue-router';
import cookie from 'vue-cookies';
import i18n from '../i18n';

// Layouts
import Index from '../views/Index.vue';
import Dashboard from '../views/Dashboard.vue';

// Main pages
import Home from '../components/index/home/Page.vue';
import Login from '../components/dashboard/login/Page.vue';
import Booking from '../components/index/booking/Page.vue';
import Transactions from '../components/dashboard/dashboard/Transactions.vue';
import Transaction from '../components/dashboard/dashboard/Transaction.vue';
import TransactionsAdmin from '../components/dashboard/dashboard/TransactionsAdmin.vue';
import AddTransaction from '../components/dashboard/dashboard/AddTransaction.vue';
import Users from '../components/dashboard/dashboard/Users.vue';
import AddUser from '../components/dashboard/dashboard/AddUser.vue';
import User from '../components/dashboard/dashboard/User.vue';
import Payment from '../components/index/payment/Payment.vue';
import Analytics from '../components/dashboard/dashboard/Analytics.vue';
import Reports from '../components/dashboard/dashboard/Reports.vue';
import Report from '../components/dashboard/dashboard/Report.vue';
import AddReport from '../components/dashboard/dashboard/AddReport.vue';


import AddTransactionFromFolder from '../components/dashboard/dashboard/AddTransactionFromFolder.vue';
// Error pages
import NotFound from '../components/utils/404.vue';

const routes = [
    {
        path: '/:lang(en|ar)',
        component: Index,
        children: [
            {
                path: '',
                beforeEnter: () => {
                    window.location.href = 'https://dorchesterprt.com'
                },
                name: 'Home',
                //component: Home,
                meta: {
                    title: 'Home | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Home | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                },
            },
            {
                path: 'book',
                beforeEnter: () => {
                    window.location.href = 'https://booking.dorchesterprt.com'
                },
                name: 'Booking',
                //component: Booking,
                meta: {
                    title: 'Booking | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Booking | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'payment/:id',
                name: 'Payment',
                component: Payment,
                meta: {
                    title: 'Payment | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Payment | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://portal.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                },
            },
        ]
    },
    {
        path: '/:lang(en|ar)/!',
        component: Dashboard,
        children: [
            // Transactions
            {
                path: '',
                name: 'Transactions',
                component: Transactions,
                meta: {
                    role: ['user', 'admin', 'superUser'],
                    requiresAuth: true,
                    title: 'Transactions | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Dashboard | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: ':id(\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b)',
                name: 'Transaction',
                component: Transaction,
                meta: {
                    role: ['user', 'admin', 'superUser'],
                    requiresAuth: true,
                    title: 'Transaction | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Dashboard | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'all',
                name: 'AllTransactions',
                component: TransactionsAdmin,
                meta: {
                    role: ['admin', 'superUser'],
                    requiresAuth: true,
                    title: 'All transactions | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Dashboard | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'reports',
                name: 'AllReports',
                component: Reports,
                meta: {
                    role: ['reportUser', 'admin'],
                    requiresAuth: true,
                    title: 'All Reports | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'All Reports | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'report/:id(\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b)',
                name: 'Report',
                component: Report,
                meta: {
                    role: ['reportUser', 'admin'],
                    requiresAuth: true,
                    title: 'Report | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Report | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'add-report',
                name: 'AddReport',
                component: AddReport,
                meta: {
                    role: ['reportUser', 'admin'],
                    requiresAuth: true,
                    title: 'Add Report | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Add Report | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'add-transaction',
                name: 'AddTransaction',
                component: AddTransaction,
                meta: {
                    role: ['user', 'admin', 'superUser'],
                    requiresAuth: true,
                    title: 'Add Transaction | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Add Transaction | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'add-transaction-from-folder',
                name: 'AddTransactionFromFolder',
                component: AddTransactionFromFolder,
                meta: {
                    role: ['user', 'admin', 'superUser'],
                    requiresAuth: true,
                    title: 'Add Transaction (Folder) | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Add Transaction (Folder) | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    role: ['admin'],
                    requiresAuth: true,
                    title: 'Users | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Users | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'add-user',
                name: 'AddUser',
                component: AddUser,
                meta: {
                    role: ['admin'],
                    requiresAuth: true,
                    title: 'Add User | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Add User | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'user/:id',
                name: 'User',
                component: User,
                meta: {
                    role: ['user', 'admin', 'superUser', 'reportUser'],
                    requiresAuth: true,
                    title: 'User | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'User | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'analytics',
                name: 'Analytics',
                component: Analytics,
                meta: {
                    role: ['admin'],
                    requiresAuth: true,
                    title: 'Analytics | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Analytics | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    title: 'Login | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: ''
                        },
                        {
                            property: 'og:title',
                            content: 'Login | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://www.dorchester.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: ''
                        }
                    ]
                }
            },
        ]
    },
    {
        path: '/:pathMatch(.*)',
        name: '404',
        component: Index,
        children: [
            {
                path: '',
                component: NotFound,
                meta: {
                    title: '404 | Dorchester',
                    metaTags: [
                        {
                            name: 'description',
                            content: 'The page you are looking for does not exist'
                        },
                        {
                            property: 'og:title',
                            content: 'Not Found | Dorchester'
                        },
                        {
                            property: 'og:url',
                            content: 'https://portal.dorchesterprt.com'
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            property: 'og:description',
                            content: 'The page you are looking for does not exist'
                        }
                    ]
                }
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {

    let language = to.params.lang;

    switch (language) {
        case 'ar':
            i18n.global.locale = language;
            break;
        case 'en':
            i18n.global.locale = language;
            break;
        default:
            if (to.path.split('/')[1] == 'ar') i18n.global.locale = 'ar';
            else if (to.path.split('/')[1] == 'en') i18n.global.locale = 'en';
            else {
                i18n.global.locale = 'en';
                console.log(`/en${to.path}`)
                next(`/en${to.path}`);
                return;
            }
            break;
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));


    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach(tag => document.head.appendChild(tag)); // Add the meta tags to the document head.

    let refreshCookie = cookie.get('refreshCookie');
    let role = cookie.get('role');

    if (to.matched.some(record => record?.meta?.requiresAuth) && refreshCookie != null && refreshCookie == 'true') {
        if (to.matched.some(record => record?.meta?.role?.includes(role))) {
            next();
        } else {
            if (role == 'reportUser') next(`/${i18n.global.locale}/!/reports`);
            else next(`/${i18n.global.locale}/!`);
        }
    } else if (to.matched.some(record => record?.meta?.requiresAuth) && refreshCookie == null) {
        next(`/${i18n.global.locale}/!/login`);
    } else if ((refreshCookie != null && refreshCookie == 'true') && to.path == `/${i18n.global.locale}/!/login`) {
        next(`/${i18n.global.locale}/!`);
    } else {
        next();
    }
});

export default router;