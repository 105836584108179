<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 text-center">
                <div class="header" :class="[ $i18n.locale == 'en' ? 'start' : 'end' ]">
                    <h1>{{ $t('dashboard.users.title') }}</h1>
                    <button class="btn btn-add" @click.prevent="$router.push(`/${$i18n.locale}/!/add-user`)"><img src="../../../assets/icons/plus-w.png" alt="add"></button>
                </div>
                <div class="table-responsive" :class="[ $i18n.locale == 'en' ? 'text-start' : 'text-end' ]">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in columns">{{ column }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="$i18n.locale == 'en'">
                            <tr v-bind:key="index" v-for="(user, index) in users">
                                <td>{{ user.role }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.loginMethod }}</td>
                                <td>{{ user.loginCount }}</td>
                                <td>{{ user.failedLoginCount }}</td>
                                <td>{{ returnTimePassed(user.lastLogin, $i18n.locale) }}</td>
                                <td><router-link :to="`/${$i18n.locale}/!/user/${user.id}`">{{ $t('dashboard.users.view') }}</router-link></td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-bind:key="index" v-for="(user, index) in users">
                                <td><router-link :to="`/${$i18n.locale}/!/user/${user.id}`">{{ $t('dashboard.users.view') }}</router-link></td>
                                <td>{{ returnTimePassed(user.lastLogin, $i18n.locale) }}</td>
                                <td>{{ user.failedLoginCount }}</td>
                                <td>{{ user.loginCount }}</td>
                                <td>{{ user.loginMethod }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.role }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="users.length == 0 && usersComplete">{{ $t('dashboard.users.noneFound') }}</p>
                <p v-if="users.length != 0 && usersComplete">{{ $t('dashboard.users.allLoaded') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { returnTimePassed } from '../../../utils/formatters';
export default {
    name: 'Users',
    data() {
        return {
            columns: [
                this.$t('dashboard.users.role'),
                this.$t('dashboard.users.name'),
                this.$t('dashboard.users.email'),
                this.$t('dashboard.users.loginMethod'),
                this.$t('dashboard.users.loginCount'),
                this.$t('dashboard.users.failedLoginCount'),
                this.$t('dashboard.users.lastLogin'),
                this.$t('dashboard.users.view'),
            ],
            loading: false,
            search: '',
            limit: 20,
            offset: 0,
            users: [],
            usersComplete: false,
        }
    },
    methods: {
        returnTimePassed,
        ...mapActions([
            'fetchAccounts',
        ]),
        async getUsers() {
            if(this.loading || this.usersComplete) return;
            let payload = {};
            payload.limit = this.limit;
            payload.offset = this.offset;
            this.loading = true;
            await this.fetchAccounts(payload)
            .then(data => {
                this.users.push(...data.accounts);
                this.offset += data.accounts.length;
                if(data.accounts.length < this.limit) {
                    this.usersComplete = true;
                }
            })
            .catch(err => {
                this.$toast.clear();
                this.$toast.error(err.msg || err.status);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        handleScroll() {
            const documentOffsetHeight = document.documentElement.offsetHeight;
            const documentScrollTop = document.documentElement.scrollTop;
            const windowInnerHeight = window.innerHeight;

            const atBottomOfWindow = documentScrollTop + windowInnerHeight == documentOffsetHeight || documentScrollTop + windowInnerHeight > documentOffsetHeight - 20;
            if(atBottomOfWindow && !this.loading && this.search == '') {
                this.getUsers();
            }
        },
    },
    async created() {
        if(this.$i18n.locale == 'ar') {
            this.columns.reverse();
        }
        await this.getUsers();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
        '$i18n.locale'() {
            if(this.$i18n.locale == 'en') {
                this.columns = [
                    this.$t('dashboard.users.role'),
                    this.$t('dashboard.users.name'),
                    this.$t('dashboard.users.email'),
                    this.$t('dashboard.users.loginMethod'),
                    this.$t('dashboard.users.loginCount'),
                    this.$t('dashboard.users.failedLoginCount'),
                    this.$t('dashboard.users.lastLogin'),
                    this.$t('dashboard.users.view'),
                ];
            } else {
                this.columns = [
                    this.$t('dashboard.users.role'),
                    this.$t('dashboard.users.name'),
                    this.$t('dashboard.users.email'),
                    this.$t('dashboard.users.loginMethod'),
                    this.$t('dashboard.users.loginCount'),
                    this.$t('dashboard.users.failedLoginCount'),
                    this.$t('dashboard.users.lastLogin'),
                    this.$t('dashboard.users.view'),
                ];
                this.columns.reverse();
            }
        }
    }
}
</script>

<style scoped>
.container {
    min-height: 110vh;
}

table.table.ar {
    text-align: end;
}

.nowrap {
    white-space: nowrap;
}

.en {
    font-family: lato-bold;
}

.ar {
    font-family: tajawal-bold;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: flex-start;
}

.header.end {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.header button {
    margin: 0 5px;
}

button img {
    height: 12px;
}
</style>