import axios from 'axios';
import store from './store/index';
import router from './router/index';
import app from './main';

// OFFLINE MODE
const baseURL = 'https://api.dorchesterprt.com';
//const baseURL = 'http://localhost:3000';

const axiosApiInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 1000*120,
    timeoutErrorMessage: 'Server connection timeout. Please try again later.',
});

axiosApiInstance.interceptors.request.use((config) => {
	app.config.globalProperties.$Progress.start();
	return config;
})

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  app.config.globalProperties.$Progress.finish();
  return response;
}, async function (error) {
  app.config.globalProperties.$Progress.fail();
  const originalRequest = error.config;
  const url = originalRequest.url.split('/');
  const isToken = url[url.length - 1] === 'token' ? true : false;
  if (error.response.status == 401 && !originalRequest._retry && !isToken) {
    originalRequest._retry = true;
    await store.dispatch('refreshAccessToken');
    return axiosApiInstance(originalRequest);
  } else if(error.response.status == 401) {
    router.push('/!/login');
  } else {
    return Promise.reject(error);
  }
});

export default axiosApiInstance;