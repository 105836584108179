<template>
    <div v-bind:class="[ on ? 'on' : 'off']" @click.prevent="$emit('overlay-off')"></div>
</template>

<script>
export default {
    name: 'Overlay',
    props: [
        'on',
    ],
    emits: [
        'overlay-off'
    ],
}
</script>

<style scoped>
div {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity 500ms ease 0s;
    z-index: 9999;
}

div.off {
    pointer-events: none;
    opacity: 0;
}

div.on {
    opacity: 1;
    pointer-events: all;
}
</style>