import axiosApiInstance from "../api";
import { error } from '../utils/apiHelpers';

export default {
    state: {},
    mutations: {},
    actions: {
        fetchTransactionNoAuth(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction/noauth/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        checkTransactionPayment(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.patch('/transaction/payment/' + payload.id + '/' + payload.foloosiID)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        }
    },
}