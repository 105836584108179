<template>
    <div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
                <h1>{{ $t('dashboard.analytics.title') }}</h1>
            </div>
        </div>
        <div class="row justify-content-center d-flex align-items-end" :class="[ $i18n.locale == 'en' ? '' : 'rtl' ]">
            <div class="col-sm-5 col-md-5 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="transactionDate">{{ $t('dashboard.analytics.from') }}</label>
                <v-date-picker v-model="from" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                    <input type="text"
                        class="form-control"
                        id="transactionDate"
                        :value="inputValue"
                        v-on="inputEvents"
                        :class="[ $i18n.locale == 'en' ? '' : 'text-end' ]"
                        readonly
                        />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="transactionDate">{{ $t('dashboard.analytics.to') }}</label>
                <v-date-picker v-model="to" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                    <input type="text"
                        class="form-control"
                        id="transactionDate"
                        :value="inputValue"
                        v-on="inputEvents"
                        :class="[ $i18n.locale == 'en' ? '' : 'text-end' ]"
                        readonly />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-3 col-md-2 col-xl-1 mb-2 mb-sm-0">
                <button class="btn btn-main btn-search" @click.prevent="getAnalytics()">{{ $t('dashboard.transactions.search') }}</button>
            </div>
        </div>
        <br/>
        <div class="col-12 col-lg-12 mb-3" v-if="loaded">
				<div class="card">
					<h4>{{ $t("dashboard.analytics.stats") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="totalRevenue" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.totalRevenue") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="totalRevenue"
										v-model="totalRevenue"
										maxlength="100"
									/>
								</div>
							</div>
                            <div class="row mb-3">
								<label for="officeRevenue" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.officeRevenue") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="officeRevenue"
										v-model="officeRevenue"
										maxlength="100"
									/>
								</div>
							</div>
                            <div class="row mb-3">
								<label for="onlineRevenue" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.onlineRevenue") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="onlineRevenue"
										v-model="onlineRevenue"
										maxlength="100"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="applicationFee" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.applicationFee") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="applicationFee"
										v-model="applicationFee"
										maxlength="100"
									/>
								</div>
							</div>
							<div class="row mb-3">
								<label for="comission" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.comission") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="comission"
										v-model="comission"
										maxlength="100"
									/>
								</div>
							</div>
                            <div class="row mb-3">
								<label for="profit" class="col-form-label col-sm-3">{{ $t("dashboard.analytics.profit") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="profit"
										v-model="profit"
										maxlength="100"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <div class="row justify-content-center" v-if="loaded">
            <div class="col-8 col-lg-8 text-center">
                <div class="table-responsive" :class="[ $i18n.locale == 'en' ? 'text-start' : 'text-end' ]" style="overflow-y:scroll; max-height: 600px;">
                    <table class="table table-hover caption-top">
                        <caption :class="[ $i18n.locale == 'en' ? 'text-start' : 'text-end' ]">{{ $t('dashboard.transactions.title') }} ({{ transactionCount }})</caption>
                        <thead style="position: sticky; top: 0; background-color: white;">
                            <tr>
                                <th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in columns">{{ column }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-bind:key="index" v-for="(transaction, index) in transactions">
                                <td>{{ new Date(transaction.transactionDate).toLocaleDateString('en-GB') }}</td>
                                <td>{{ (transaction.totalAmount/100).toFixed(2) }}</td>
                                <!-- <td>{{ (transaction.commissionAmount/100).toFixed(2) }}</td> -->
                                <td width="80%"><router-link :to="transaction.id">{{ transaction.sellerName }}-{{ transaction.buyerName }}</router-link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-4 col-lg-4 text-center">
                <div class="table-responsive" :class="[ $i18n.locale == 'en' ? 'text-start' : 'text-end' ]" style="overflow-y:scroll; max-height: 600px;">
                    <table class="table table-hover caption-top">
                        <caption :class="[ $i18n.locale == 'en' ? 'text-start' : 'text-end' ]">{{ $t('dashboard.reports.title') }} ({{ reportsCount }})</caption>
                        <thead style="position: sticky; top: 0; background-color: white;">
                            <tr>
                                <th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in reportColumns">{{ column }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-bind:key="index" v-for="(report, index) in reports">
                                <td>{{ new Date(report.reportDate).toLocaleDateString('en-GB') }}</td>
                                <td>{{ report.type }}</td>
                                <td><router-link :to="'report/' + report.id">{{ report.name }}</router-link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import dateFormat from "dateformat";
export default {
    name: 'Analytics',
    data() {
        return {
            loading: false,
            transactions: [],
            reports: [],
            from: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1, 
                new Date().getDate()
            ),
            to: new Date(),
            transactionCount: null,
            totalRevenue: null,
            officeRevenue: null,
            onlineRevenue: null,
            applicationFee: null,
            comission: null,
            reportsCount: null,
            profit: null,
            loaded: false,
            formatter: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AED',
            }),
            columns: [
                this.$t('dashboard.transactions.date'),
                this.$t('dashboard.transactions.amount'),
                // this.$t('dashboard.transaction.commissionAmount'),
                this.$t('dashboard.report.name'),
            ],
            reportColumns: [
                this.$t('dashboard.transactions.date'),
                this.$t('dashboard.report.type'),
                this.$t('dashboard.report.name'),
            ],
            selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
        }
    },
    methods: {
        ...mapActions([
            'fetchAnalytics',
        ]),
        async getAnalytics() {
            if(this.loading || this.transactionsComplete) return;
            let payload = {};
            if(this.from != '') {
                 payload.after = dateFormat(this.from, 'dd-mm-yyyy');
            }
            if(this.to != '') {
                 payload.before = dateFormat(this.to, 'dd-mm-yyyy');
            }

            this.loading = true;
            await this.fetchAnalytics(payload)
            .then(data => {
                this.transactions = data.transactions;
                this.reports = data.reports;
                this.transactionCount = data.transactionCount
                this.totalRevenue = (data.totalRevenue/100).toFixed(2)
                this.officeRevenue = (data.officeRevenue/100).toFixed(2)
                this.onlineRevenue = (data.onlineRevenue/100).toFixed(2)
                this.applicationFee = (data.applicationFee/100).toFixed(2)
                this.comission = (data.comission/100).toFixed(2)
                this.reportsCount = data.reportsCount
                this.profit = data.totalRevenue - data.applicationFee - data.comission
                this.profit = (this.profit/100).toFixed(2)

                this.totalRevenue = this.formatter.format(this.totalRevenue)
                this.officeRevenue = this.formatter.format(this.officeRevenue)
                this.onlineRevenue = this.formatter.format(this.onlineRevenue)
                this.applicationFee = this.formatter.format(this.applicationFee)
                this.comission = this.formatter.format(this.comission)
                this.profit = this.formatter.format(this.profit)
                this.loaded = true;
            })
            .catch(err => {
                this.$toast.clear();
                this.$toast.error(err.msg || err.status);
            })
            .finally(() => {
                this.loading = false;
            });
        },

    },
    async created() {
        // if(this.$i18n.locale == 'ar') {
        //     this.columns.reverse();
        //     this.reportColumns.reverse();
        // }
        await this.getAnalytics();
    },
    watch: {
        '$i18n.locale'() {
            if(this.$i18n.locale == 'en') {
                this.columns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.transactions.amount'),
                    // this.$t('dashboard.transaction.commissionAmount'),
                    this.$t('dashboard.report.name')
                ];
                this.reportColumns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.report.type'),
                    this.$t('dashboard.report.name')
                ];
            } else {
                this.columns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.transactions.amount'),
                    // this.$t('dashboard.transaction.commissionAmount'),
                    this.$t('dashboard.report.name')
                ];
                this.reportColumns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.report.type'),
                    this.$t('dashboard.report.name')
                ];
                // this.columns.reverse();
                // this.reportColumns.reverse();
            }
        }   
    }
}
</script>

<style scoped>
h1 span {
	font-size: 1rem;
	margin: 0 5px;
}

.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.container[dir="rtl"] h4 span {
	font-family: tajawal-regular;
}

.container h4 span {
	font-family: lato-regular;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header .buttons {
	display: flex;
}

.header .buttons > button {
	margin-right: 5px;
}

.header .buttons > button:last-of-type {
	margin-right: 0;
}

*[dir="rtl"] .header .buttons > button:last-of-type {
	margin-right: 5px;
}

*[dir="rtl"] .header .buttons > button:first-of-type {
	margin-right: 0;
}

table.table.ar {
    text-align: end;
}

.btn-upload {
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin: 0;
	width: 100%;
}

*[dir="rtl"] .form-control[type="file"]::before {
  content: "اختر المرفقات";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eaecef;
  width: 90px;
  transition: .1675s background-color;
}

*[dir="rtl"] .form-control[type="file"]:hover::before {
	background-color: #dee0e3;
}

*[dir="rtl"] .form-control[type="file"]::after {
	content: "لم يتم اختيار أي مرفقات";
	position: absolute;
	z-index: 3;
	display: block;
	width: 90px;
	top: 50px;
	right: 126px;
	background-color: #ffffff;
}

*[dir="rtl"] .form-control[type="file"].of::after {
	display: none;
}

*[dir="rtl"] .form-control[type="file"].mf::after {
	content: 'مرفقات';
	right: 138px;
}

*[dir="rtl"] .form-control[type="file"].mf10::after {
	content: '10+ مرفقات';
	right: 126px;
}

@media only screen and (max-width: 1400px) {
	.header {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 767px) {
	.header .buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
	.header .buttons button {
		margin: 0;
	}
}


.container {
    min-height: 110vh;
}

table.table.ar {
    text-align: end;
}

.nowrap {
    white-space: nowrap;
}

.en {
    font-family: lato-bold;
}

.ar {
    font-family: tajawal-bold;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: flex-start;
}

.header.end {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.header button {
    margin: 0 5px;
}

button img {
    height: 12px;
}

.btn-search {
    margin: 0;
    padding: 0.375rem 1rem;
    width: 100%;
}

td {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>