<template>
	<Overlay :on="dialogueReport.show || dialogueFile.show" @overlayOff="dialogueReport.show = false; dialogueFile.show = false;"></Overlay>
	<Dialogue v-if="dialogueReport.show" :title="$t('dashboard.report.deleteMessage')" :loading="loading" :deleteDialogue="true" :ok="$t('dashboard.report.deleteReport')" :cancel="$t('dashboard.transaction.cancel')" @yes="delReport" @cancel="dialogueReport.show = false;"></Dialogue>
	<Dialogue v-if="dialogueFile.show" :title="$t('dashboard.report.deleteMessageFile')" :loading="loading" :deleteDialogue="true" :ok="$t('dashboard.transaction.yes')" :cancel="$t('dashboard.transaction.cancel')" @yes="delFile" @cancel="dialogueFile.show = false;">
		<slot>
			<p>{{ fileToDelete }}</p>
		</slot>
	</Dialogue>
	<Dialogue v-if="dialogueFilePreview.show" :title="fileToShow.name" :loading="loading" :fileShowDialogue="true" :ok="$t('dashboard.transaction.closeFile')" @yes="dialogueFilePreview.show = false; fileToShow = null">
		<slot>
			<!-- <PSPDFKitContainer v-if="false" :pdfUrl="fileToShow.url" /> -->
			<WebViewer :pdfUrl="fileToShow.url"/>
		</slot>
	</Dialogue>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1>{{ $t("dashboard.report.title") }}<span>{{ report.id }}</span></h1>
					<div class="buttons">
						<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{ $t("dashboard.report.save") }}</button>
						<button class="btn btn-delete" @click.prevent="dialogueReport.show = true;" :disabled="loading">{{ $t("dashboard.report.deleteReport") }}</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.report.generalInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.report.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.name }"
										type="text"
										class="form-control"
										id="name"
										v-model="inputs.name"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.name">
										{{ errors.name }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="type" class="col-form-label col-sm-3">{{ $t("dashboard.report.type") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.type" v-bind:class="{ invalid : errors.type }">
                                        <option selected :value="report.type">{{ report.type }}</option>
                                        <option v-if="report.type != $t('dashboard.report.types.daily.id')" :value="$t('dashboard.report.types.daily.id')">{{ $t('dashboard.report.types.daily.value') }}</option>
                                        <option v-if="report.type != $t('dashboard.report.types.weekly.id')" :value="$t('dashboard.report.types.weekly.id')">{{ $t('dashboard.report.types.weekly.value') }}</option>
										<option v-if="report.type != $t('dashboard.report.types.monthly.id')" :value="$t('dashboard.report.types.monthly.id')">{{ $t('dashboard.report.types.monthly.value') }}</option>
										<option v-if="report.type != $t('dashboard.report.types.quarterly.id')" :value="$t('dashboard.report.types.quarterly.id')">{{ $t('dashboard.report.types.quarterly.value') }}</option>
										<option v-if="report.type != $t('dashboard.report.types.yearly.id')" :value="$t('dashboard.report.types.yearly.id')">{{ $t('dashboard.report.types.yearly.value') }}</option>
										<option v-if="report.type != $t('dashboard.report.types.other.id')" :value="$t('dashboard.report.types.other.id')">{{ $t('dashboard.report.types.other.value') }}</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.type">
										{{ errors.type }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="reportDate" class="col-form-label col-sm-3">{{ $t("dashboard.report.reportDate") }}</label>
								<div class="col-sm-9">
									<v-date-picker v-model="inputs.reportDate" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
										<template v-slot="{ inputValue, inputEvents }">
										<input type="text"
											class="form-control"
											id="reportDate"
											:value="inputValue"
											v-on="inputEvents"
											readonly />
										</template>
									</v-date-picker>
								</div>
							</div>
							<div class="row mb-3">
								<label for="trustee" class="col-form-label col-sm-3">{{ $t("dashboard.report.accountName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.accountName }"
										type="email"
										class="form-control"
										id="trustee"
										readonly
										v-model="inputs.accountName"
									/>
									<div class="invalid-feedback" v-if="errors.accountName">
										{{ errors.accountName }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="notes" class="col-form-label col-sm-3">{{ $t("dashboard.report.notes") }}</label>
								<div class="col-sm-9">
								<textarea
									class="form-control"
									id="notes"
									rows="3"
									v-model="inputs.notes"
									maxlength="500"
								></textarea>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="status" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.status") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										id="status"
										readonly
										v-model="inputs.status"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.attachments") }}</h4>
					<div class="row mb-3">
						<div class="col-12">
							<form @submit.prevent="validateForm" novalidate>
								<div class="form-group row">
									<div class="col-sm-8 col-lg-9 col-xl-10">
										<div class="custom-file">
											<input type="file" class="form-control" :class="{ of : files.length == 1, mf : files.length > 1 && files.length < 10, mf10 : files.length >= 10 }" id="files" v-on:change="filesValidation" accept="image/*, application/*" multiple>
										</div>
									</div>
									<div class="col-sm-4 col-lg-3 col-xl-2 mt-2 mt-sm-0">
										<button type="submit" @click.prevent="upload(inputs.id)" class="btn btn-main btn-upload" v-bind:disabled="loading">{{ $t("dashboard.transaction.upload") }}</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table align-middle">
									<thead>
										<tr>
											<th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in columns">{{ column }}</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(file, index) in fileList">
											<td>{{ index + 1 }}</td>
											<td>{{ file.name }}</td>
											<td>{{ Math.round(file.size / 1000) }}KB</td>
											<td v-if="file.location == 'S3'">
												<a href="#" @click.prevent="fetchFileUrl(file.key, true)" target="_blank">{{ $t('dashboard.transaction.download') }}</a><br>
												<a href="#" @click.prevent="fetchFileUrl(file.key, false, file.name)" target="_blank">{{ $t('dashboard.transaction.view') }}</a>
											</td>
											<td v-else>
												<p>{{ $t('dashboard.transaction.fso') }}</p>
											</td>
											<td><button class="btn btn-delete" :disabled="file.location != 'S3'" style="margin: 0;" @click.prevent="fileToDelete = file.name; fileToDeleteKey = file.key; dialogueFile.show = true;">{{ $t('dashboard.transaction.delete') }}</button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validator from 'validator';
import { mapActions } from "vuex";
import Dialogue from '../../utils/Dialogue';
import Overlay from '../../utils/Overlay';
import WebViewer  from "../../utils/WebViewer";

export default {
	name: "Report",
	data() {
		return {
			loading: false,
			formValidated: false,
			report: {
			},
			inputs: {
				reportDate: new Date(),
				type: null
			},
			errors: {
				name: null,
				type: null,
				notes: null,
				accountName: null
			},
			files: [],
			fileList: [],
			columns: [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.fileSize'),
				this.$t('dashboard.transaction.fileLink'),
				this.$t('dashboard.transaction.delete'),
			],
			selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
			dialogueReport: {
				show: false,
			},
			dialogueFile: {
				show: false,
			},
			fileToDelete: null,
			fileToDeleteKey: null,
			fileToShow: null,
			dialogueFilePreview: {
				show: false,
			},
		}
	},
	methods: {
		...mapActions([
			"fetchReport",
			"fetchReportFiles",
			"updateReport",
			"deleteReport",
			"deleteFile",
			"uploadReportFiles",
			"fetchReportFile"
		]),
		statusFormatterEn(status) {
			switch (status) {
				case "PAID":
					return "Paid";
				case "PENDING":
					return "Pending";
				case "PAID-INOFFICE":
					return "Paid in office";
				case "REFUNDED":
					return "Refunded";
				case "CANCELLED":
					return "Cancelled";
				default:
					return status;
			}
		},
		statusFormatterAr(status) {
			switch (status) {
				case "PAID":
					return "تم الدفع";
				case "PENDING":
					return "غير مدفوع";
				case "PAID-INOFFICE":
					return "تم الدفع في المكتب";
				case "REFUNDED":
					return "المبلغ معاد";
				case "CANCELLED":
					return "ملغي";
				default:
					return status;
			}
		},
		async validateForm() {
			if(this.loading) return;
			this.formValidated = true;
			
	
				let payload = {};
				payload.patch = {};

				if(this.inputs.name != this.report.name) payload.patch.name = this.inputs.name;
				if(this.inputs.type != this.report.type) payload.patch.type = this.inputs.type;
				if(this.inputs.reportDate.getTime() != this.report.reportDate.getTime()) payload.patch.reportDate = this.inputs.reportDate.toISOString();
				if(this.inputs.notes != this.report.notes) payload.patch.notes = this.inputs.notes;


				if(Object.keys(payload.patch).length == 0) return;

				payload.id = this.report.id;
				this.loading = true;
				await this.updateReport(payload)
				.then(() => {
					this.$toast.show('Details successfully updated');
				})
				.catch(err => {
					this.$toast.clear();
					this.$toast.show(err.msg);
				})
				.finally(() => {
					this.loading = false;
				});
			
		},
		async upload(id) {
            let payload = {};
            payload.id = id;
			if(this.files.length) {
				payload.files = this.files;      
				this.loading = true;
				await this.uploadReportFiles(payload)
				.then(() => {
					this.$toast.show('Your files have been uploaded');
					this.files = [];
				})
				.catch(err => {
					this.$toast.clear();
					this.$toast.error(err.msg || err.status);
				})
				.finally(() => {
					setTimeout(async () => {
						let payload = {};
						payload.id = this.$route.params.id;
						await this.fetchReportFiles(payload)
						.then(data => {
							this.fileList = data.files;
							this.$forceUpdate();
							this.loading = false;
						})
					}, 2000);
				});
			}
        },
		async filesValidation(e) {
            const fileList = e.target.files;
            if(fileList.length == 0) return;
            this.files = fileList;
        },
		async delReport() {
			if(this.loading) return;
			this.dialogueReport.show = false;
			let payload = {};
			payload.id = this.report.id;
			this.loading = true;
			await this.deleteReport(payload)
			.then(() => {
				this.$toast.show('Report has been deleted');
				this.$router.push(`/${this.$i18n.locale}/!/reports`);
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.show(err.msg);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		async fetchFileUrl(key, download = false, name = '') {
			if(this.loading) return;
			let payload = {};
			payload.id = this.report.id;
			payload.key = key;
			this.loading = true;
			await this.fetchReportFile(payload)
			.then((res) => {
				console.log(res.file)
				this.fileToShow = res.file;
				if(download == true) window.location = res.file.url;
				else {
					this.fileToShow = { name, url: res.file.url };
					this.dialogueFilePreview.show = true;
				}
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.show(err.msg);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		async delFile() {
			if(this.loading) return;
			this.dialogueFile.show = false;
			let payload = {};
			payload.id = this.report.id;
			payload.fileName = this.fileToDelete;
			payload.key = this.fileToDeleteKey;
			this.loading = true;
			await this.deleteFile(payload)
			.then(() => {
				this.$toast.show('File has been deleted');
				this.fileList.splice(this.fileList.findIndex(file => {
					return file.key == this.fileToDeleteKey;
				}), 1);
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.show(err.msg);
			})
			.finally(() => {
				this.loading = false;
				this.fileToDelete = null;
				this.fileToDeleteKey = null;
			});
		},
		nameValid() {
			if(validator.isEmpty(this.inputs.fulfillerName)) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name cannot be empty' : 'خانة الإسم فارغة';
				return false;
			}
			if(this.inputs.fulfillerName.length > 100) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name is too long' : 'الاسم طويل جدا';
				return false;
			}
			this.errors.fulfillerName = null;
			return true;
		},
	},
	async created() {
		this.loading = true;

		let payload = {};
		payload.id = this.$route.params.id;
		await Promise.all([
			await this.fetchReport(payload).then((data) => {
				this.report = {...data.report};
				this.report.reportDate = new Date(this.report.reportDate);
				this.report.reportDate.setHours(12);
				this.inputs = {...data.report};
				this.inputs.reportDate = new Date(this.inputs.reportDate);
				this.inputs.reportDate.setHours(12);
			}).catch((err) => {
			this.$toast.clear();
			this.$toast.error(err.msg || err.status);
			}),
			await this.fetchReportFiles(payload)
			.then(data => {
				for(let file of data.files) {
					file.show = false;
					file.url = '#';
				}
				this.fileList = data.files;
			}).catch(() => {})
		])
		.catch((err) => {
			this.$toast.clear();
			this.$toast.error(err.msg || err.status);
		})
		.finally(() => {
			this.loading = false;
		});
	},
	watch: {
		'$i18n.locale'() {
            if(this.formValidated) {
                this.nameValid();
            }

			this.columns = [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.fileSize'),
				this.$t('dashboard.transaction.fileLink'),
				this.$t('dashboard.transaction.delete'),
			];
		},
		'inputs.name'() {
			if(this.formValidated) this.nameValid();
		}
	},
	components: {
		Dialogue,
		Overlay,
		WebViewer
	}
}
</script>

<style scoped>
h1 span {
	font-size: 1rem;
	margin: 0 5px;
}

.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.container[dir="rtl"] h4 span {
	font-family: tajawal-regular;
}

.container h4 span {
	font-family: lato-regular;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header .buttons {
	display: flex;
}

.header .buttons > button {
	margin-right: 5px;
}

.header .buttons > button:last-of-type {
	margin-right: 0;
}

*[dir="rtl"] .header .buttons > button:last-of-type {
	margin-right: 5px;
}

*[dir="rtl"] .header .buttons > button:first-of-type {
	margin-right: 0;
}

table.table.ar {
    text-align: end;
}

.btn-upload {
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin: 0;
	width: 100%;
}

*[dir="rtl"] .form-control[type="file"]::before {
  content: "اختر المرفقات";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eaecef;
  width: 90px;
  transition: .1675s background-color;
}

*[dir="rtl"] .form-control[type="file"]:hover::before {
	background-color: #dee0e3;
}

*[dir="rtl"] .form-control[type="file"]::after {
	content: "لم يتم اختيار أي مرفقات";
	position: absolute;
	z-index: 3;
	display: block;
	width: 90px;
	top: 50px;
	right: 126px;
	background-color: #ffffff;
}

*[dir="rtl"] .form-control[type="file"].of::after {
	display: none;
}

*[dir="rtl"] .form-control[type="file"].mf::after {
	content: 'مرفقات';
	right: 138px;
}

*[dir="rtl"] .form-control[type="file"].mf10::after {
	content: '10+ مرفقات';
	right: 126px;
}

@media only screen and (max-width: 1400px) {
	.header {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 767px) {
	.header .buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
	.header .buttons button {
		margin: 0;
	}
}
</style>