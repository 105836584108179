const error = (err) => {
    if(err.response) {
        return {
            serverDown: false,
            msg: err.response.data.error,
            status: err.response.status,
        }
    } else if(err.request) {
        return {
            serverDown: true,
            msg: 'Service is down. Please try again later.'
        }
    } else {
        return {
            serverDown: true,
            msg: 'Service is down or your internet may not be working right now. Please try again later.'
        }
    }
};

export {
    error,
}