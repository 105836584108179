<template>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1> {{ $t("dashboard.addReport.title") }}</h1>
					<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{ $t("dashboard.addTransaction.add") }}</button>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.report.generalInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.report.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.sellerName }"
										type="text"
										class="form-control"
										id="name"
										v-model="inputs.name"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.sellerName">
										{{ errors.sellerName }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="type" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.status") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.type" v-bind:class="{ invalid : errors.type }">
                                        <option disabled selected value="null">{{ $t('dashboard.addReport.chooseType') }}</option>
                                        <option :value="$t('dashboard.report.types.daily.id')">{{ $t('dashboard.report.types.daily.value') }}</option>
                                        <option :value="$t('dashboard.report.types.weekly.id')">{{ $t('dashboard.report.types.weekly.value') }}</option>
										<option :value="$t('dashboard.report.types.monthly.id')">{{ $t('dashboard.report.types.monthly.value') }}</option>
										<option :value="$t('dashboard.report.types.quarterly.id')">{{ $t('dashboard.report.types.quarterly.value') }}</option>
										<option :value="$t('dashboard.report.types.yearly.id')">{{ $t('dashboard.report.types.yearly.value') }}</option>
										<option :value="$t('dashboard.report.types.other.id')">{{ $t('dashboard.report.types.other.value') }}</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.type">
										{{ errors.type }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="reportDate" class="col-form-label col-sm-3">{{ $t("dashboard.report.reportDate") }}</label>
								<div class="col-sm-9">
									<v-date-picker v-model="inputs.reportDate" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
										<template v-slot="{ inputValue, inputEvents }">
										<input type="text"
											class="form-control"
											id="reportDate"
											:value="inputValue"
											v-on="inputEvents"
											readonly />
										</template>
									</v-date-picker>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="notes" class="col-form-label col-sm-3">{{ $t("dashboard.report.notes") }}</label>
								<div class="col-sm-9">
								<textarea
									class="form-control"
									id="notes"
									rows="3"
									v-model="inputs.notes"
									maxlength="500"
								></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "AddTransaction",
	data() {
		return {
			loading: false,
			formValidated: false,
			inputs: {
                name: '',
				type: null,
				reportDate: new Date(),
                notes: '',
			},
			errors: {
				name: null,
				type: null,
				notes: null
			},
			files: [],
			selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
		}
	},
	methods: {
		...mapActions([
			"addReport"
		]),
		async validateForm() {
			if(this.loading) return;
			this.formValidated = true;

            const typeValid = this.typeValid();
			
			if(typeValid) {

					let payload = {};

					payload.name = this.inputs.name;
					payload.type = this.inputs.type;
					payload.reportDate = this.inputs.reportDate.toISOString();
					payload.notes = this.inputs.notes;

					this.loading = true;
					await this.addReport(payload)
					.then(data => {
						this.$toast.show('Report successfully added');
                        this.$router.push(`/${this.$i18n.locale}/!/report/${data.id}`);
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.show(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
        typeValid() {
            if(this.inputs.type == null) {
                this.errors.type = this.$i18n.locale == 'en' ? 'Please choose a type' : 'خانة النوع فارغة';
				return false;
            }
            this.errors.type = null;
			return true;
        }
	},
	watch: {
		'$i18n.locale'() {
            if(this.formValidated) {
                this.typeValid();
            }
		},
        'inputs.type'() {
			if(this.formValidated) this.typeValid();
		}
	}
}
</script>

<style scoped>
.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
</style>