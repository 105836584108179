<template>
	<Overlay :on="dialogueTransaction.show || dialogueFile.show || dialogueInvoice.show || dialogueFilePreview.show" @overlayOff="dialogueTransaction.show = false; dialogueFile.show = false; dialogueInvoice.show = false; dialogueFilePreview.show = false;"></Overlay>
	<Dialogue v-if="dialogueTransaction.show" :title="$t('dashboard.transaction.deleteMessage')" :loading="loading" :deleteDialogue="true" :ok="$t('dashboard.transaction.cancelTransaction')" :cancel="$t('dashboard.transaction.cancel')" @yes="delTransaction" @cancel="dialogueTransaction.show = false;"></Dialogue>
	<Dialogue v-if="dialogueFile.show" :title="$t('dashboard.transaction.deleteMessageFile')" :loading="loading" :deleteDialogue="true"  :ok="$t('dashboard.transaction.yes')" :cancel="$t('dashboard.transaction.cancel')" @yes="delFile" @cancel="dialogueFile.show = false;">
		<slot>
			<p>{{ fileToDelete }}</p>
		</slot>
	</Dialogue>
	<Dialogue v-if="dialogueInvoice.show" :title="$t('dashboard.transaction.createInvoice')" :loading="loading" :invoiceDialogue="true" :ok="$t('dashboard.transaction.invoiceYes')" :cancel="$t('dashboard.transaction.cancel')" @yes="crtInvoice" @cancel="dialogueInvoice.show = false;">
		<slot>
			<div class="card scroll">
				<div class="row mb-3">
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoiceDate" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceDate") }}</label>
								<div class="col-sm-9">
									<v-date-picker v-model="invoice.date" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
										<template v-slot="{ inputValue, inputEvents }">
										<input type="text"
											class="form-control"
											id="invoiceDate"
											:value="inputValue"
											v-on="inputEvents"
											readonly />
										</template>
									</v-date-picker>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoiceName" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceName }"
										type="text"
										class="form-control"
										id="invoiceName"
										v-model="invoice.name"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceName">
										{{ errors.invoiceName }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="invoiceAddress" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceAddress") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceAddress }"
										type="text"
										class="form-control"
										id="invoiceAddress"
										v-model="invoice.address"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceAddress">
										{{ errors.invoiceAddress }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoiceCity" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceCity") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceCity }"
										type="text"
										class="form-control"
										id="invoiceCity"
										v-model="invoice.city"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceCity">
										{{ errors.invoiceCity }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="invoiceZip" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceZip") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceZip }"
										type="text"
										class="form-control"
										id="invoiceZip"
										v-model="invoice.invoiceZip"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceZip">
										{{ errors.invoiceZip }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoiceCountry" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceCountry") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceCountry }"
										type="text"
										class="form-control"
										id="invoiceCountry"
										v-model="invoice.country"
										maxlength="20"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceCountry">
										{{ errors.invoiceCountry }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="invoiceEmail" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceEmail") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoiceEmail }"
										type="text"
										class="form-control"
										id="invoiceEmail"
										v-model="invoice.email"
										maxlength="20"
									/>
									<div class="invalid-feedback" v-if="errors.invoiceEmail">
										{{ errors.invoiceEmail }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoicePhone" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoicePhone") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.invoicePhone }"
										type="number"
										class="form-control"
										id="invoicePhone"
										v-model="invoice.phone"
									/>
									<div class="invalid-feedback" v-if="errors.invoicePhone">
										{{ errors.invoicePhone }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoiceNotes" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceNotes") }}</label>
								<div class="col-sm-9">
								<textarea
									class="form-control"
									id="invoiceNotes"
									rows="3"
									v-model="invoice.notes"
									maxlength="500"
								></textarea>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<h6>
									<b>{{ $t("dashboard.transaction.invoiceItems") }}</b>
								</h6>
							</div>
						</div>
						<div class="col-lg-12">
							<div v-for="(item, index) in invoice.items" v-bind:key="index" class="row mb-3">
								<div class="col-sm-3">
									<p>{{ $t("dashboard.transaction.invoiceItem") }} {{index + 1}} <button class="btn btn-link btn-invoice-remove" v-if="invoice.items.length > 1" @click.prevent="removeItem(index)" :disabled="loading">{{ $t("dashboard.transaction.invoiceRemoveItem") }}</button>			</p>							</div>							
								<div class="col-sm-5">
								<input v-bind:class="{ invalid : errors.invoiceName }"
										type="text"
										class="form-control"
										id="invoiceItemName"
										v-model="item.name"
										:key="index"
										placeholder="Name"
									/>
								</div>
								<div class="col-sm-2">
								<input v-bind:class="{ invalid : errors.invoicePhone }"
										type="number"
										class="form-control"
										id="invoiceItemQuantity"
										v-model="item.quantity"
										:key="index"
										placeholder="Qty"
									/>
								</div>
								<div class="col-sm-2">
								<input v-bind:class="{ invalid : errors.invoicePhone }"
										type="number"
										class="form-control"
										id="invoiceItemPrice"
										v-model="item.price"
										:key="index"
										placeholder="Price"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3 justify-content-md-center">
								<div class="col-sm-3">
									<button class="btn btn-main btn-invoice-add" @click.prevent="addItem" :disabled="loading">{{ $t("dashboard.transaction.invoiceAddItem") }}</button>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoicePayment" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoicePaid") }}</label>
								<div class="col-sm-3">
									<div class="form-check form-switch">
										<input 
										type="checkbox"
										class="form-check-input" 
										id="invoicePaid"
										v-model="invoice.invoicePaid" 
										role="switch" 
										/>
									</div>
								</div>
								<div class="col-sm-6">
									<select class="form-select" v-if="invoice.invoicePaid" v-model="invoice.paidBy" v-bind:class="{ invalid : errors.status }">
											<option disabled selected value="null">{{ $t('dashboard.transaction.choosePayment') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.bank.id')">{{ $t('dashboard.transaction.paidBy.bank.value') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.online.id')">{{ $t('dashboard.transaction.paidBy.online.value') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.cash.id')">{{ $t('dashboard.transaction.paidBy.cash.value') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.card.id')">{{ $t('dashboard.transaction.paidBy.card.value') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.crypto.id')">{{ $t('dashboard.transaction.paidBy.crypto.value') }}</option>
											<option :value="$t('dashboard.transaction.paidBy.other.id')">{{ $t('dashboard.transaction.paidBy.other.value') }}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row mb-3">
								<label for="invoicePayment" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.invoiceSendEmail") }}</label>
								<div class="col-sm-3">
									<div class="form-check form-switch">
										<input 
										type="checkbox"
										class="form-check-input" 
										id="invoiceSendEmail"
										v-model="invoice.invoiceSendEmail" 
										role="switch" 
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</slot>
	</Dialogue>
	<Dialogue v-if="dialogueFilePreview.show && fileToShow.location=='S3'" :title="fileToShow.name" :loading="loading" :fileShowDialogue="true" :ok="$t('dashboard.transaction.closeFile')" @yes="dialogueFilePreview.show = false; fileToShow = null">
		<slot>
			<!-- <PSPDFKitContainer v-if="false" :pdfUrl="fileToShow.url" /> -->
			<WebViewer :pdfUrl="fileToShow.url"/>
		</slot>
	</Dialogue>
	<Dialogue v-if="dialogueFilePreview.show && fileToShow.location=='FSO'" :title="fileToShow.name" :loading="loading" :fileShowDialogue="true" :ok="$t('dashboard.transaction.closeFile')" @yes="dialogueFilePreview.show = false; fileToShow = null">
		<slot>
			<WebViewerB64 :name="fileToShow.name" :pdfB64="fileToShow.fileB64"/>
		</slot>
	</Dialogue>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1>{{ $t("dashboard.transaction.title") }}<span>{{ transaction.id }}</span></h1>
					<div class="buttons">
						<button v-if="transaction.status == 'PENDING'" class="btn btn-main" @click.prevent="sendNotification" :disabled="loading">{{ !transaction.sentPaymentEmail ? $t("dashboard.transaction.sendLink") : $t("dashboard.transaction.resendLink") }}</button>
						<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{ $t("dashboard.transaction.save") }}</button>
						<button class="btn btn-main" @click.prevent="dialogueInvoicePopulateData()" :disabled="loading">{{ $t("dashboard.transaction.createInvoice") }}</button>
						<button class="btn btn-delete" @click.prevent="dialogueTransaction.show = true;" :disabled="loading || !(transaction.status == 'PAID-INOFFICE' || transaction.status == 'PAID' || transaction.status == 'PENDING') || role != 'admin'">{{ $t("dashboard.transaction.cancelTransaction") }}</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.paymentInformation") }} <span><a :href="`/payment/${transaction.id}`" target="_blank" v-if="transaction.status == 'PENDING' || transaction.status == 'PAID' || transaction.status == 'REFUNDED'" :disabled="loading">{{ $t("dashboard.transaction.paymentLink") }}</a></span> <span><a v-if="transaction.payment != null" :href="`https://www.foloosi.com/merchant/transactions/details/${transaction.payment.transaction_no}`" target="_blank" :disabled="loading">{{ transaction.payment.transaction_no }}</a></span></h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerName }"
										type="text"
										class="form-control"
										id="name"
										v-model="inputs.fulfillerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerName">
										{{ errors.fulfillerName }}
									</div>
								</div>	
							</div>
							<div class="row mb-3">
								<label for="email" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.email") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerEmail }"
										type="email"
										class="form-control"
										id="email"
										v-model="inputs.fulfillerEmail"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerEmail">
										{{ errors.fulfillerEmail }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="mobile" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.mobileNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerMobileNumber }"
										type="text"
										class="form-control"
										id="mobile"
										v-model="inputs.fulfillerMobileNumber"
										maxlength="15"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerMobileNumber">
										{{ errors.fulfillerMobileNumber }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="status" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.status") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										readonly
										id="status"
										:value="$i18n.locale == 'en' ? statusFormatterEn(inputs.status) : statusFormatterAr(inputs.status)"
									/>
								</div>
							</div>
							<div class="row mb-3">
								<label for="paymentNotes" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.paymentNotes") }}</label>
								<div class="col-sm-9">
									<textarea class="form-control" id="paymentNotes" rows="3" v-model="inputs.paymentNotes" maxlength="500"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.generalInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="sellerLastName" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.sellerName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.sellerName }"
										type="text"
										class="form-control"
										id="sellerLastName"
										v-model="inputs.sellerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.sellerName">
										{{ errors.sellerName }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="buyerLastName" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.buyerName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.buyerName }"
										type="text"
										class="form-control"
										id="buyerLastName"
										v-model="inputs.buyerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.buyerName">
										{{ errors.buyerName }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="area" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.area") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.area }"
										type="text"
										class="form-control"
										id="area"
										v-model="inputs.area"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.area">
										{{ errors.area }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="building" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.building") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.building }"
										type="text"
										class="form-control"
										id="building"
										v-model="inputs.building"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.building">
										{{ errors.building }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="procedureNumber" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.procedureNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.procedureNumber }"
										type="text"
										class="form-control"
										id="procedureNumber"
										v-model="inputs.procedureNumber"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.procedureNumber">
										{{ errors.procedureNumber }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="titleDeed" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.titleDeed") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.titleDeedNumber }"
										type="text"
										class="form-control"
										id="titleDeed"
										v-model="inputs.titleDeedNumber"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.titleDeedNumber">
										{{ errors.titleDeedNumber }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="totalAmount" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.totalAmount") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.totalAmount }"
										type="number"
										class="form-control"
										id="totalAmount"
										v-model="inputs.totalAmount"
									/>
									<div class="invalid-feedback" v-if="errors.totalAmount">
										{{ errors.totalAmount }}
									</div>
								</div>
							</div>
							<!-- <div class="row mb-3">
								<label for="commissionAmount" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.commissionAmount") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.commissionAmount }"
										type="number"
										class="form-control"
										id="commissionAmount"
										v-model="inputs.commissionAmount"
									/>
									<div class="invalid-feedback" v-if="errors.commissionAmount">
										{{ errors.commissionAmount }}
									</div>
								</div>
							</div> -->
							<div class="row mb-3">
								<label for="type" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.type") }}</label>
								<div class="col-sm-9">
									<input
										type="text"
										class="form-control"
										readonly
										id="type"
										:value="$i18n.locale == 'en' ? typeFormatterEn(inputs.transactionType) : typeFormatterAr(inputs.transactionType)"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="transactionDate" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.transactionDate") }}</label>
								<div class="col-sm-9">
									<v-date-picker v-model="inputs.transactionDate" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
										<template v-slot="{ inputValue, inputEvents }">
										<input type="text"
											class="form-control"
											id="transactionDate"
											:value="inputValue"
											v-on="inputEvents"
											readonly />
										</template>
									</v-date-picker>
								</div>
							</div>
							<div class="row mb-3">
								<label for="trustee" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.accountName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.accountName }"
										type="email"
										class="form-control"
										id="trustee"
										readonly
										v-model="inputs.accountName"
									/>
									<div class="invalid-feedback" v-if="errors.accountName">
										{{ errors.accountName }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="notes" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.notes") }}</label>
								<div class="col-sm-9">
								<textarea
									class="form-control"
									id="notes"
									rows="3"
									v-model="inputs.notes"
									maxlength="500"
								></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.attachments") }} <span v-if="showFSO == true">- {{$t('dashboard.transaction.fso')}}</span></h4>
					<div class="row mb-3">
						<div class="col-12">
							<form @submit.prevent="validateForm" novalidate>
								<div class="form-group row">
									<div class="col-sm-8 col-lg-9 col-xl-10">
										<div class="custom-file">
											<input type="file" class="form-control" :class="{ of : files.length == 1, mf : files.length > 1 && files.length < 10, mf10 : files.length >= 10 }" id="files" v-on:change="filesValidation" accept="image/*, application/*" multiple>
										</div>
									</div>
									<div class="col-sm-4 col-lg-3 col-xl-2 mt-2 mt-sm-0">
										<button type="submit" @click.prevent="upload(inputs.id)" class="btn btn-main btn-upload" v-bind:disabled="loading">{{ $t("dashboard.transaction.upload") }}</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table align-middle">
									<thead>
										<tr>
											<th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index" v-for="(column, index) in columns">{{ column }}</th>
										</tr>
									</thead>
									<tbody>
											<tr :key="index" v-for="(file, index) in fileList">
												<td>{{ index + 1 }}</td>
												<td>{{ file.name }}</td>
												<td>{{ Math.round(file.size / 1000) }}KB</td>
												<td v-if="file.location == 'S3' || showFSO == true">
													<a href="#" @click.prevent="fetchFileUrl(file.key, true, file.name)" target="_blank">{{ $t('dashboard.transaction.download') }}</a><br>
													<a href="#" @click.prevent="fetchFileUrl(file.key, false, file.name)" target="_blank">{{ $t('dashboard.transaction.view') }}</a>
												</td>
												<td v-else>
													<p>{{ $t('dashboard.transaction.fso') }}</p>
												</td>
												<td><button class="btn btn-delete" :disabled="file.location != 'S3'" style="margin: 0;" @click.prevent="fileToDelete = file.name; fileToDeleteKey = file.key; dialogueFile.show = true;">{{ $t('dashboard.transaction.delete') }}</button></td>
											</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3" v-if="role == 'admin'">
				<div class="card" v-if="showHistory == false">
					<h4>{{ $t("dashboard.transaction.history") }} <span><button class="btn btn-main btn-float-right" @click.prevent="showHistory = !showHistory;">{{ $t("dashboard.transaction.showHistory") }}</button></span></h4>
				</div>
				<div class="card" v-if="showHistory == true">
					<h4>{{ $t("dashboard.transaction.history") }} ({{ Object.keys(history).length == 100 ? $t("dashboard.transaction.latest100") + ' 100' : Object.keys(history).length }}) <span><button class="btn btn-main btn-float-right" @click.prevent="showHistory = !showHistory;">{{ $t("dashboard.transaction.hideHistory") }}</button></span></h4>
					<div class="row mb-3">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th scope="col" class="text-nowrap" :class="$i18n.locale" v-bind:key="index"
												v-for="(column, index) in historyColumns">{{ column }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-bind:key="index" v-for="(h, index) in history">
											<td>{{ dateFormat(h.createdAt) }}</td>
											<td class="text-wrap">{{ h.accessDescription }}</td>
											<td>{{ h.userAgent }}</td>
											<td>{{ h.ip }}</td>
											<td class="text-wrap"><button class="btn" :disabled="inputs.id == h.accountId"><router-link :to="`/${$i18n.locale}/!/user/${h.accountId}`">{{ h.accountName }}</router-link></button></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validator from 'validator';
import { mapActions, mapGetters } from "vuex";
import Dialogue from '../../utils/Dialogue';
import Overlay from '../../utils/Overlay';
import WebViewer  from "../../utils/WebViewer";
import WebViewerB64  from "../../utils/WebViewerB64";
import { dateFormat } from '../../../utils/formatters'

export default {
	name: "Transaction",
	data() {
		return {
			// OFFLINE MODE
			showFSO: false,
			loading: false,
			formValidated: false,
			transaction: {
				payment: {
					transaction_no: "#"
				}
			},
			history: {},
			showHistory: false,
			inputs: {
				transactionDate: new Date(),
			},
			errors: {
				fulfillerName: null,
				fulfillerEmail: null,
				fulfillerMobileNumber: null,
				sellerName: null,
				buyerName: null,
				procedureNumber: null,
				titleDeedNumber: null,
				area: null,
				building: null,
				totalAmount: null,
				// commissionAmount: null,
				accountEmail: null,
				accountName: null,
				invoiceDate: null,
				invoiceDraft: false,
				invoiceName: null,
				invoiceAddress: null,
				invoiceCity: null,
				invoiceZip: null,
				invoiceCountry: null,
				invoiceEmail: null,
				invoicePhone: null,
				invoiceNotes: null,
				transactionType: null
			},
			files: [],
			fileList: [],
			columns: [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.fileSize'),
				this.$t('dashboard.transaction.fileLink'),
				this.$t('dashboard.transaction.delete'),
			],
			historyColumns: [
				this.$t('dashboard.user.date'),
				this.$t('dashboard.user.accessDescription'),
				this.$t('dashboard.user.userAgent'),
				this.$t('dashboard.user.ip'),
				this.$t('dashboard.user.actionBy'),
			],
			selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
			dialogueTransaction: {
				show: false,
			},
			dialogueFile: {
				show: false,
			},
			dialogueInvoice: {
				show: false,
			},
			invoice: {
				date: new Date(),
				draft: false,
				name: null,
				address: null,
				city: null,
				zip: null,
				country: null,
				taxSubject: false,
				email: null,
				phone: null,
				items: [{name: null, price: null, quantity: null}],
				notes: null,
				payments: [],
				invoicePaid: false,
				paidBy: null,
				invoiceSendEmail: false
			},
			fileToDelete: null,
			fileToDeleteKey: null,
			fileToShow: {
				location: ""
			},
			dialogueFilePreview: {
				show: false,
			},
		}
	},
	computed: {
		...mapGetters(['role', 'id'])
	},
	methods: {
		...mapActions([
			"fetchTransaction",
			"fetchTransactionFiles",
			"updateTransaction",
			"sendPaymentLink",
			"deleteTransaction",
			"deleteFile",
			"uploadFiles",
			"createInvoice",
			"fetchTransactionFile"
		]),
		statusFormatterEn(status) {
			switch (status) {
				case "PAID":
					return "Paid";
				case "PENDING":
					return "Pending";
				case "PAID-INOFFICE":
					return "Paid in office";
				case "REFUNDED":
					return "Refunded";
				case "CANCELLED":
					return "Cancelled";
				default:
					return status;
			}
		},
		statusFormatterAr(status) {
			switch (status) {
				case "PAID":
					return "تم الدفع";
				case "PENDING":
					return "غير مدفوع";
				case "PAID-INOFFICE":
					return "تم الدفع في المكتب";
				case "REFUNDED":
					return "المبلغ معاد";
				case "CANCELLED":
					return "ملغي";
				default:
					return status;
			}
		},
		typeFormatterEn(type) {
			switch (type) {
				case "RT":
					return "Registration";
				case "ST":
					return "Service";
				default:
					return type;
			}
		},
		typeFormatterAr(type) {
			switch (type) {
				case "RT":
					return "تسجيل";
				case "ST":
					return "خدمة";
				default:
					return type;
			}
		},
		dateFormat,
		addItem: function () {
			this.invoice.items.push({name: null, price: null, quantity: null});
		},
		removeItem: function (index) {
			this.invoice.items.splice(index, 1);
		},
		async validateForm() {
			if(this.loading) return;
			this.formValidated = true;

            const amountValid = this.amountValid();
			
			if(amountValid) {
				let payload = {};
				payload.patch = {};

				if(this.inputs.fulfillerName != this.transaction.fulfillerName && this.nameValid()) payload.patch.fulfillerName = this.inputs.fulfillerName;
				if(this.inputs.fulfillerEmail != this.transaction.fulfillerEmail && this.emailValid()) payload.patch.fulfillerEmail = this.inputs.fulfillerEmail;
				if(this.inputs.fulfillerMobileNumber != this.transaction.fulfillerMobileNumber && this.mobileValid()) payload.patch.fulfillerMobileNumber = this.inputs.fulfillerMobileNumber;
				if(this.inputs.paymentNotes != this.transaction.paymentNotes) payload.patch.paymentNotes = this.inputs.paymentNotes;
				if(this.inputs.sellerName != this.transaction.sellerName) payload.patch.sellerName = this.inputs.sellerName;
				if(this.inputs.buyerName != this.transaction.buyerName) payload.patch.buyerName = this.inputs.buyerName;
				if(this.inputs.area != this.transaction.area) payload.patch.area = this.inputs.area;
				if(this.inputs.building != this.transaction.building) payload.patch.building = this.inputs.building;
				if(this.inputs.procedureNumber != this.transaction.procedureNumber) payload.patch.procedureNumber = this.inputs.procedureNumber.toString();
				if(this.inputs.titleDeedNumber != this.transaction.titleDeedNumber) payload.patch.titleDeedNumber = this.inputs.titleDeedNumber.toString();
				if(this.inputs.transactionDate.getTime() != this.transaction.transactionDate.getTime()) payload.patch.transactionDate = this.inputs.transactionDate.toISOString();
				if(this.inputs.totalAmount * 100 != this.transaction.totalAmount && this.amountValid()) payload.patch.totalAmount = (this.inputs.totalAmount * 100).toString();
				//if(this.inputs.commissionAmount * 100 != this.transaction.commissionAmount && this.commissionValid()) payload.patch.commissionAmount = (this.inputs.commissionAmount * 100).toString();
				if(this.inputs.notes != this.transaction.notes) payload.patch.notes = this.inputs.notes;
				if(this.inputs.transactionType != this.transaction.transactionType && this.typeValid()) payload.patch.type = this.inputs.transactionType;

				if(Object.keys(payload.patch).length == 0) return;

				payload.id = this.transaction.id;
				this.loading = true;
				await this.updateTransaction(payload)
				.then(() => {
					this.$toast.show('Details successfully updated');
				})
				.catch(err => {
					this.$toast.clear();
					this.$toast.error(err.msg);
				})
				.finally(() => {
					this.loading = false;
				});
			}
		},
		async upload(id) {
            let payload = {};
            payload.id = id;
			if(this.files.length) {
				payload.files = this.files; 
				this.loading = true;
				await this.uploadFiles(payload)
				.then(() => {
					this.$toast.show('Your files have been uploaded');
					this.files = [];
				})
				.catch(err => {
					this.$toast.clear();
					this.$toast.error(err.msg || err.status);
				})
				.finally(() => {
					setTimeout(async () => {
						let payload = {};
						payload.id = this.$route.params.id;
						await this.fetchTransactionFiles(payload)
						.then(data => {
							this.fileList = data.files;
							this.$forceUpdate();
							this.loading = false;
						})
					}, 2000);
				});
			}
        },
		async filesValidation(e) {
            const fileList = e.target.files;
            if(fileList.length == 0) return;
            this.files = fileList;
        },
		async sendNotification() {
			if(this.loading) return;
			let payload = {}
			payload.id = this.transaction.id;
			this.loading = true;
			await this.sendPaymentLink(payload)
			.then(() => {
				this.$toast.show('Payment link has been sent');
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.show(err.msg);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		async delTransaction() {
			if(this.loading) return;
			this.dialogueTransaction.show = false;
			let payload = {};
			payload.id = this.transaction.id;
			this.loading = true;
			await this.deleteTransaction(payload)
			.then(() => {
				this.$toast.show('Transaction has been deleted');
				this.transaction.status = this.inputs.status = 'CANCELLED';
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.error(err.msg || err.status);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		async delFile() {
			if(this.loading) return;
			this.dialogueFile.show = false;
			let payload = {};
			payload.id = this.transaction.id;
			payload.fileName = this.fileToDelete;
			payload.key = this.fileToDeleteKey;
			this.loading = true;
			await this.deleteFile(payload)
			.then(() => {
				this.$toast.show('File has been deleted');
				this.fileList.splice(this.fileList.findIndex(file => {
					return file.key == this.fileToDeleteKey;
				}), 1);
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.error(err.msg || "File cannot be deleted. Contact Admin");
			})
			.finally(() => {
				this.loading = false;
				this.fileToDelete = null;
				this.fileToDeleteKey = null;
			});
		},
		base64ToBlob(base64) {
			const binaryString = window.atob(base64);
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; ++i) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return new Blob([bytes]);
		},
		forceFileDownload(fileBase64, name) {
			console.log(name)
			const url = window.URL.createObjectURL(this.base64ToBlob(fileBase64))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', name)
			document.body.appendChild(link)
			link.click()
		},
		async fetchFileUrl(key, download = false, name = '') {
			console.log("name", name)
			if(this.loading) return;
			let payload = {};
			payload.id = this.transaction.id;
			payload.key = key;
			this.loading = true;
			await this.fetchTransactionFile(payload)
			.then((res) => {
				if(res.file == null) throw { msg: "File Unavailable. Please contact support"}
				console.log(res)
				if(res.location === 'S3') {
					if(download == true) window.location = res.file.url;
					else {
						this.fileToShow = { name, url: res.file.url, location: res.location };
						this.dialogueFilePreview.show = true;
					}
				} else if(res.location === 'FSO' && this.showFSO === true) {
					if(download == true) this.forceFileDownload(res.file, name)
					else {
						this.fileToShow = { name, fileB64: res.file, location: res.location };
						this.dialogueFilePreview.show = true;
					}
				} else {
					throw { msg: "Unknown File Location. Please contact support."}
				}
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.error(err.msg);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		async crtInvoice() {
			if(this.loading) return;
			let payload = {};
			payload.id = this.transaction.id;
			payload.date= this.invoice.date.toISOString()
			payload.draft= this.invoice.draft
			payload.name= this.invoice.name
			payload.address= this.invoice.address
			payload.city= this.invoice.city
			payload.zip= this.invoice.zip
			payload.country= this.invoice.country
			payload.taxSubject= this.invoice.taxSubject
			payload.email= this.invoice.email
			payload.phone= this.invoice.phone
			for(let i in this.invoice.items) {
				try {
				this.invoice.items[i].price = this.invoice.items[i].price.toString()
				this.invoice.items[i].quantity = this.invoice.items[i].quantity.toString()
				} catch(e) {
					this.$toast.clear();
					this.$toast.error(this.$t('dashboard.transaction.priceAndQuantityRequired'));
					return false;
				}
			}
			payload.items= this.invoice.items
			payload.notes= this.invoice.notes
			if(this.invoice.invoicePaid == true) {
				payload.payments = [{"type": this.invoice.paidBy}]
				if(this.invoice.paidBy == "" || this.invoice.paidBy == null) {
					this.$toast.clear();
					this.$toast.error(this.$t('dashboard.transaction.invoicePaymentMethodRequired'));
					return false;
				}
			}
			payload.sendDocumentByEmail= this.invoice.invoiceSendEmail
			if(this.invoice.invoiceSendEmail && (this.invoice.email == "" || this.invoice.email == null)) {
					this.$toast.clear();
					this.$toast.error(this.$t('dashboard.transaction.invoiceEmailRequired'));
					return false;
			}
			this.loading = true;
			await this.createInvoice(payload)
			.then((res) => {
				this.dialogueInvoice.show = false;
				setTimeout(async () => {
						let payload = {};
						payload.id = this.$route.params.id;
						await this.fetchTransactionFiles(payload)
						.then(data => {
							this.fileList = data.files;
							this.$forceUpdate();
							this.loading = false;
						})
				}, 2000);
				if(res == 1) {
					this.$toast.show(this.$t('dashboard.transaction.createInvoiceResult1'));
				} else if (res == 2) {
					this.$toast.show(this.$t('dashboard.transaction.createInvoiceResult2'));
				} else {
					this.$toast.show(this.$t('dashboard.transaction.createInvoiceResult3'));
				}
			})
			.catch(err => {
				this.$toast.clear();
				this.$toast.error(err.msg || err.status);
			})
			.finally(() => {
				this.loading = false;
			});
		},
		dialogueInvoicePopulateData() {
			if(this.loading) return;
			this.invoice.date = this.inputs.transactionDate;
			this.invoice.name = this.inputs.fulfillerName;
			this.invoice.email = this.inputs.fulfillerEmail;
			this.invoice.phone = this.inputs.fulfillerMobileNumber;
			this.invoice.notes = this.inputs.paymentNotes;
			this.dialogueInvoice.show = true;
		},
		nameValid() {
			if(validator.isEmpty(this.inputs.fulfillerName)) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name cannot be empty' : 'خانة الإسم فارغة';
				return false;
			}
			if(this.inputs.fulfillerName.length > 100) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name is too long' : 'الاسم طويل جدا';
				return false;
			}
			this.errors.fulfillerName = null;
			return true;
		},
		mobileValid() {
			if(!validator.isMobilePhone(this.inputs.fulfillerMobileNumber)) {
				this.errors.fulfillerMobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			if(this.inputs.fulfillerMobileNumber.length > 15) {
				this.errors.fulfillerMobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			this.errors.fulfillerMobileNumber = null;
			return true;
		},
		emailValid() {
			if(!validator.isEmail(this.inputs.fulfillerEmail)) {
				this.errors.fulfillerEmail = this.$i18n.locale == 'en' ? 'Email is incorrect' : 'الإيميل غير صحيح';
				return false;
			}
			if(this.inputs.fulfillerEmail.length > 100) {
				this.errors.fulfillerEmail = this.$i18n.locale == 'en' ? 'Email is too long' : 'البريد الإلكتروني طويل جدًا';
				return false;
			}
			this.errors.fulfillerEmail = null;
			return true;
		},
		amountValid() {
			if(!validator.isNumeric(this.inputs.totalAmount.toString())) {
				this.errors.totalAmount = this.$i18n.locale == 'en' ? 'Amount is incorrect' : 'قيمة الدفع غير صحيحة';
				return false;
			}
			if(this.inputs.totalAmount > 10000000) {
				this.errors.totalAmount = this.$i18n.locale == 'en' ? 'Amount is more than the maximum allowed payment' : 'المبلغ أكثر من الحد الأقصى للدفع المسموح به';
				return false;
			}
			this.errors.totalAmount = null;
			return true;
		},
		// commissionValid() {
		// 	if(!validator.isNumeric(this.inputs.commissionAmount.toString())) {
		// 		this.errors.commissionAmount = this.$i18n.locale == 'en' ? 'Amount is incorrect' : 'قيمة العمولة غير صحيحة';
		// 		return false;
		// 	}
		// 	if(this.inputs.commissionAmount > 10000000) {
		// 		this.errors.commissionAmount = this.$i18n.locale == 'en' ? 'Amount is more than the maximum allowed payment' : 'المبلغ أكثر من الحد الأقصى للدفع المسموح به';
		// 		return false;
		// 	}
		// 	this.errors.commissionAmount = null;
		// 	return true;
		// },
		typeValid() {
			if(this.inputs.transactionType != "ST" || this.inputs.transactionType != "RT") {
				this.errors.transactionType = this.$i18n.locale == 'en' ? 'Transaction Type is incorrect' : 'نوع المعاملة غير صحيح';
				return false;
			}
			this.errors.transactionType = null;
			return true;
		},
	},
	async created() {
		this.loading = true;

		let payload = {};
		payload.id = this.$route.params.id;
		await Promise.all([
			await this.fetchTransaction(payload).then((data) => {
				this.transaction = {...data.transaction};
				this.transaction.transactionDate = new Date(this.transaction.transactionDate);
				this.transaction.transactionDate.setHours(12);
				this.inputs = {...data.transaction};
				this.inputs.transactionDate = new Date(this.inputs.transactionDate);
				this.inputs.transactionDate.setHours(12);
				this.inputs.totalAmount = (this.inputs.totalAmount / 100).toFixed(2);
				// this.inputs.commissionAmount = (this.inputs.commissionAmount / 100).toFixed(2);
				if (data.history != null) {
					this.history = { ...data.history };
				}
			}).catch((err) => {
			this.$toast.clear();
			this.$toast.error(err.msg || err.status);
			}),
			await this.fetchTransactionFiles(payload)
			.then(data => {
				for(let file of data.files) {
					file.show = false;
					file.url = '#';
				}
				this.fileList = data.files;
			}).catch(() => {})
		])
		.catch((err) => {
			this.$toast.clear();
			this.$toast.error(err.msg || err.status);
		})
		.finally(() => {
			this.loading = false;
		});
	},
	watch: {
		'$i18n.locale'() {
            if(this.formValidated) {
                this.nameValid();
                this.mobileValid();
                this.emailValid();
                this.amountValid();
				// this.commissionValid();
				this.accountEmailValid();
            }

			this.columns = [
				this.$t('dashboard.transaction.index'),
				this.$t('dashboard.transaction.fileName'),
				this.$t('dashboard.transaction.fileSize'),
				this.$t('dashboard.transaction.fileLink'),
				this.$t('dashboard.transaction.delete'),
			];


			this.historyColumns = [
				this.$t('dashboard.user.date'),
				this.$t('dashboard.user.accessDescription'),
				this.$t('dashboard.user.userAgent'),
				this.$t('dashboard.user.ip'),
				this.$t('dashboard.user.actionBy'),
			];
		},
		'inputs.fulfillerName'() {
			if(this.formValidated) this.nameValid();
		},
		'inputs.fulfillerMobileNumber'() {
			if(this.formValidated) this.mobileValid();
		},
        'inputs.fulfillerEmail'() {
			if(this.formValidated) this.emailValid();
		},
        'inputs.totalAmount'() {
            if(this.formValidated) this.amountValid();
		},
		// 'inputs.commissionValid'() {
        //     if(this.formValidated) this.commissionValid();
		// },
		'inputs.accountEmail'() {
			if(this.formValidated) this.accountEmailValid();
		}
	},
	components: {
		Dialogue,
		Overlay,
		WebViewer,
		WebViewerB64
	}
}
</script>

<style scoped>
h1 span {
	font-size: 1rem;
	margin: 0 5px;
}

.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.container[dir="rtl"] h4 span {
	font-family: tajawal-regular;
}

.container h4 span {
	font-family: lato-regular;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header .buttons {
	display: flex;
}

.header .buttons > button {
	margin-right: 5px;
}

.header .buttons > button:last-of-type {
	margin-right: 0;
}

*[dir="rtl"] .header .buttons > button:last-of-type {
	margin-right: 5px;
}

*[dir="rtl"] .header .buttons > button:first-of-type {
	margin-right: 0;
}

table.table.ar {
    text-align: end;
}

.btn-upload {
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin: 0;
	width: 100%;
}

.btn-float-right {
	float: right;
}

*[dir="rtl"] .btn-float-right{
  float: left;
}

.btn-invoice-remove {
	margin: 0;
	font-size: x-small;
}

.btn-invoice-add {
	margin: 0;
	width: 100%;
}

*[dir="rtl"] .form-control[type="file"]::before {
  content: "اختر المرفقات";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eaecef;
  width: 90px;
  transition: .1675s background-color;
}

*[dir="rtl"] .form-control[type="file"]:hover::before {
	background-color: #dee0e3;
}

*[dir="rtl"] .form-control[type="file"]::after {
	content: "لم يتم اختيار أي مرفقات";
	position: absolute;
	z-index: 3;
	display: block;
	width: 90px;
	top: 50px;
	right: 126px;
	background-color: #ffffff;
}

*[dir="rtl"] .form-control[type="file"].of::after {
	display: none;
}

*[dir="rtl"] .form-control[type="file"].mf::after {
	content: 'مرفقات';
	right: 138px;
}

*[dir="rtl"] .form-control[type="file"].mf10::after {
	content: '10+ مرفقات';
	right: 126px;
}

@media only screen and (max-width: 1400px) {
	.header {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 767px) {
	.header .buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
	.header .buttons button {
		margin: 0;
	}
}

.scroll {
    max-height: 600px;
    overflow-y: auto;
}
</style>