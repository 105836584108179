<template>
    <Header></Header>
    <Services></Services>
    <About></About>
    <ContactUs></ContactUs>
</template>

<script>
import Header from './Header';
import Services from './Services';
import About from './About';
import ContactUs from './ContactUs';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Home',
    mounted() {
        if(!this.tawkInitialized) this.initializeTawk();
    },
    methods: {
        ...mapActions(['initializeTawk']),
    },
    computed: {
        ...mapGetters(['tawkInitialized']),
    },
    components: {
        Header,
        Services,
        About,
        ContactUs,
    }
}
</script>