<template>
    <div class="container header" id="header" :style="headerMarginBottom">
        <div class="row">
            <div class="col-12 text-center d-flex justify-content-center">
                <div class="main-card">
                    <h1>{{ $t('index.home.title') }}</h1>
                    <h5>{{ $t('index.home.subtitle') }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            headerMarginBottom: ''
        }
    },
    methods: {
        handleResize() {
            let elem = document.getElementById('header');
            this.headerMarginBottom = `margin-bottom: calc(100vh - 72.88px - ${elem.offsetHeight}px - ${window.getComputedStyle(elem).marginTop})`;
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>

<style scoped>
.container.header {
    margin-top: 30vh;
}

.main-card {
    background-color: rgba(82, 77, 73, 0.541);
    backdrop-filter: blur(3px);
    padding: 40px 5px 35px;
    border-radius: 5px;
    width: 90%;
    z-index: 10;
}

.container.header h1 {   
    color: #f7f6ff;
}

.container.header h5 {
    color: #f0e5ce;
}

@media only screen and (max-width: 575px) {
    .container.header .col-12 {
        padding: 0 !important;
    }

    .main-card {
        border-radius: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .main-card {
        width: 100%;
    }
}
</style>