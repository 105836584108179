<template>
    <div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
        <div class="row justify-content-center d-flex align-items-center">
            <div class="col-xxl-6 col-xl-7 col-lg-8 col-md-10 col-sm-12">
                <div class="card">
                    <h2>{{ title }}</h2>
                    <slot>
                        <p>{{ description }}</p>
                    </slot>
                    <div class="buttons">
                        <button type="button" v-if="deleteDialogue == true" @click="$emit('yes');" class="btn btn-delete" :disabled="loading">{{ ok }}</button>
                        <button type="button" v-if="invoiceDialogue == true" @click="$emit('yes');" class="btn btn-main" :disabled="loading">{{ ok }}</button>
                        <button v-if="deleteDialogue == true" type="button" @click="$emit('cancel');" class="btn btn-main" :disabled="loading">{{ cancel }}</button>
                        <button v-if="invoiceDialogue == true" type="button" @click="$emit('cancel');" class="btn btn-delete" :disabled="loading">{{ cancel }}</button>
                        <button v-if="fileShowDialogue == true" type="button" @click="$emit('yes');" class="btn btn-main" :disabled="loading">{{ ok }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dialogue',
    props: [
        'title',
        'loading',
        'ok',
        'cancel',
        'okButtonClass',
        'deleteDialogue',
        'invoiceDialogue',
        'description',
        'fileShowDialogue'
    ],
    emits: [
        'yes',
        'cancel'
    ]
}
</script>

<style scoped>
.outer {
    z-index: 10000;
}

.buttons {
	display: flex;
	gap: 5px;
}

.card {
    background-color: #ffffff;
    border-radius: 2.5px;
    z-index: 100;
    border: solid #ced4da 1px;
    padding: 30px;
}

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}

h2 {
    margin-bottom: 10px;
    display: inline-block;
    font-family: lato-bold;
}

*[dir="rtl"] h2 {
    font-family: tajawal-bold;
}
</style>